import React, { useState } from 'react'
import styled from 'styled-components'
import { MenuItem } from '@mui/material';
import Select from '@mui/material/Select';
import { useTranslation } from "react-i18next";

import { useScrollPosition } from '../../../hooks/useScroll';

import Logo from "../../../assets/images/logo2.png"
import Language from "../../../assets/icons/Language.svg"
import Arrow2 from "../../../assets/icons/Arrow.svg"
import Uzb from "../../../assets/icons/Uzb.svg"
import English from "../../../assets/icons/English.svg"
import Russia from "../../../assets/icons/Russia.svg"
import Arrow from '../../../assets/icons/Arrow';
import { useNavigate, Link } from 'react-router-dom';

const Navbar = () => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const useScroll = useScrollPosition()
    const [open, setOpen] = useState(false)
    const [lang, setLang] = React.useState(localStorage.getItem("i18nextLng"));

    const handleChange = (event) => {
        setLang(event.target.value);
        localStorage.setItem("i18nextLng", `${event.target.value}`)
        window.location.reload();
    };
    React.useEffect(() => {
        if (open)
            window.document.body.classList.add("kill-scroll")
        else
            window.document.body.classList.remove("kill-scroll")
    }, [open])

    return (
        <StyledNavbar open={open} useScroll={useScroll} id="main" >
            <div className='top-navbar'>
                <div className='container'>
                    <div className='navbar'>
                        <div className='navbar-left'>
                            <div className='logo'>
                                <a href='#main' >
                                    <img src={Logo} alt="logo" />
                                </a>
                            </div>
                            <div className='call'>
                                <p>
                                    {t("call_center")} <a href='tel:+998 69 210-10-10'>+998 69 210-10-10</a>
                                </p>
                            </div>
                        </div>
                        <div className='navbar-right'>
                            <div className='select'>
                                <img src={Language} alt="Language" className='lang' />
                                <img src={Arrow2} alt="Arrow" className='arrow MuiSelect-iconOpen' />
                                <Select
                                    sx={{ minWidth: 120, '.MuiOutlinedInput-notchedOutline': { border: 0 } }}
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={lang}
                                    onChange={handleChange}
                                >
                                    <MenuItem value={"uz"}>UZ</MenuItem>
                                    <MenuItem value={"ru"}>RU</MenuItem>
                                    <MenuItem value={"en"}>EN</MenuItem>
                                </Select>
                            </div>
                            <div className='menu' onClick={() => setOpen(true)} >=</div>
                        </div>
                    </div>
                </div>

            </div>
            <div className='sub-navbar'>
                <div className='container'>
                    <ul className='navbar-list'>
                        <li className='navbar-item' onClick={() => setOpen(false)} >
                            <a href="#about_us">{t("about_us")}</a>
                        </li>
                        <li className='navbar-item' onClick={() => setOpen(false)} >
                            <a href="#branch">{t("branch")}</a>
                        </li>
                        <li className='navbar-item' onClick={() => setOpen(false)} >
                            <a href="#result">{t("result")}</a>
                        </li>
                        <li className='navbar-item' onClick={() => setOpen(false)} >
                            <a href="#news">{t("ziyo_news")}</a>
                        </li>
                        <li className='navbar-item' onClick={() => setOpen(false)} >
                            <a href="#teacher">{t("teacher")}</a>
                        </li>
                        {/* <li className='navbar-item' onClick={() => setOpen(false)} >
                            <a href="#test">Test natijalari</a>
                        </li> */}
                        <li className='navbar-item' onClick={() => setOpen(false)
                        } > <Link to={"/work"} > {t("lets_work")}</Link>
                        </li>
                        <li className='navbar-item' onClick={() => setOpen(false)} ><Link to={"/suggest"} >{t("director_msg")}</Link>
                        </li>
                        <div className='select'>
                            <div className='lang_wrap'>
                                <img src={lang === "uz" ? Uzb : (lang === "en" ? English : Russia)} alt="Language" className='lang' />
                            </div>
                            <div className='arrow' >
                                <Arrow />
                            </div>
                            <Select
                                sx={{ minWidth: 120, '.MuiOutlinedInput-notchedOutline': { border: 0 } }}
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={lang}
                                onChange={handleChange}
                            >
                                <MenuItem value={"uz"}>UZ</MenuItem>
                                <MenuItem value={"ru"}>RU</MenuItem>
                                <MenuItem value={"en"}>EN</MenuItem>
                            </Select>
                        </div>
                    </ul>
                    <div onClick={() => setOpen(false)} className="shadow" >
                        <div className='close' onClick={() => setOpen(false)}>+</div>
                    </div>

                </div>
            </div>
        </StyledNavbar>
    )
}

export default Navbar

const StyledNavbar = styled.div`
position: relative;

.top-navbar{
    background: #FFFFFF;
    box-shadow: 0px 1px 15px rgba(0, 0, 0, 0.08);
    .menu{
        display: none;
    }
    .navbar{
        display: flex;
        justify-content: space-between;
        padding: 15px 0;
        align-items: center;

        &-left{
            display: flex;
            gap: 14px;
            align-items: center;
            .logo{
                max-width: 130px;
                img{
                    width: 100%;
                }
            }
            .call{
                p{
                    font-weight: 600;
                    line-height: 20px;
                    color: #454545;
                    a{
                        color:  #232323;
                        text-decoration: none;
                    }
                }
            }
        }

        &-right{
            display: flex;
            gap: 14px;
            align-items: center;
            .select{
                position: relative;

                .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary{
                    border-radius: 16px;
                    background: #FFF;
                    box-shadow:0px 0px 10px rgba(0, 0, 0, 0.1);
                    border:none;
                    outline: none;
                    text-align: center;
                    font-weight: 600;
                    color: #454545;

                    .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.MuiSelect-icon.MuiSelect-iconOutlined{
                        display: none;
                    }
                }

                .lang{
                    position: absolute;
                    top:50%;
                    right:14px;
                    transform: translateY(-50%);
                    z-index: 8;
                    pointer-events: none;
                }
                .arrow{
                    position: absolute;
                    top:50%;
                    left:10px;
                    transform: translateY(-50%);
                    z-index: 8;
                    pointer-events: none;
                    width: 24px;
                    
                }
            }
        }
    }
}

.sub-navbar{
    padding: 24px 0;
    .shadow{
        display: none;
    }
    .navbar-list{
        width: 80%;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: space-between;
        list-style: none;
        .navbar-item{
            font-weight: 600;
            font-size: 16px;
            line-height: 20px;
            color: #0065FF;
            cursor: pointer;
            a{
                text-decoration: none;
            }
        }
        .select{
            display:none;
        }
    }
}
@media (max-width: 1250px){
    .sub-navbar{
        .navbar-list{
            width: 100%;
        }
        }
    }

@media (max-width: 992px) {
    .sub-navbar{
        padding: 0;
        position: fixed;
        top:0;
        left:${(props) => props.open ? 0 : "-100%"};
        bottom:0;
        height:100vh;
        transition: all 0.2s;
        background: transparent;
        background: linear-gradient(45deg, #0065FF 0%, #4E9DEA 100%);

        z-index: 10;
        .container{
            padding:0;
            display:flex;
            height: 100%;
        }
        .navbar-list{
            width: 300px;
            flex-direction: column;
            height:100%;
            justify-content: flex-start;
            align-items: flex-start;
            padding: 72px 16px;
            position: relative;

            .select{
                position: relative;
                display: block;
                margin-top: 24px;

                .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary{
                    border-radius: 40px;
                    background: rgba(255, 255, 255, 0.15);
                    box-shadow:0px 0px 10px rgba(0, 0, 0, 0.1);
                    border:none;
                    outline: none;
                    color:#fff;
                    text-align: center;
                    .MuiSelect-select.MuiSelect-outlined.MuiInputBase-input.MuiOutlinedInput-input{
                        padding-right: 0 !important;
                    }
                    .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.MuiSelect-icon.MuiSelect-iconOutlined{
                        display: none;
                    }
                    .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1 {
                        width:100%;
                        left: 40px !important;
                    }
                }
                .lang_wrap{
                    position: absolute;
                    top:50%;
                    left:14px;
                    transform: translateY(-50%);
                    z-index: 8;
                    pointer-events: none;
                    width: 38px;
                    height:38px;
                    border-radius: 50%;
                    border:1px solid white;
                    .lang{
                        width: 100%;
                        height: 100%;
                    }

                }

                .arrow{
                    position: absolute;
                    top:55%;
                    right:10px;
                    transform: translateY(-50%);
                    z-index: 8;
                    pointer-events: none;
                    svg{
                        width: 24px;
                        height: 24px;
                        path{
                            fill:white;
                        }
                    }
                }
            }

            .navbar-item{
                background: rgba(255, 255, 255, 0.15);
                border-radius: 24px;
                font-weight: 600;
                font-size: 24px;
                width: 100%;
                line-height: 29px;
                padding: 13px 16px;
                margin-bottom: 8px;
                a{
                    color: #FFFFFF;
                }
            }
        }
        .shadow{
            width:72px;
            position: relative;
            display: block;

            .close{
                position: absolute;
                display: flex;
                background: #EDEDED;

                border-radius: 50px;
                width:40px;
                height: 40px;
                align-items: center;
                justify-content: center;
                font-size: 36px;
                font-weight: 700;
                cursor: pointer;
                transform: rotate(45deg);
                top:72px;
                left:16px;
            }
        }
    }

    .top-navbar{
        position: ${(props) => props.useScroll > 50 ? "fixed" : "initial"};
        top:0%;
        left:0;
        right:0;
        z-index: 9;
        box-shadow: ${(props) => props.useScroll > 50 ? "0px 1px 15px rgba(0, 0, 0, 0.1)" : "none"};
        border-radius: 0px 0px 16px 16px;
        .call{
           display: ${(props) => props.useScroll > 50 ? "none" : "block"}
        }
        .menu{
            display: flex;
            background: #EDEDED;
            border-radius: 50px;
            width:40px;
            height: 40px;
            align-items: center;
            justify-content: center;
            font-size: 36px;
            font-weight: 700;
            cursor: pointer;
        }
        .navbar{
            &-right{
                .select{
                    display: none;
                }
            }
        }
    }
}

@media (max-width:768px) {
    padding-top: 60px;
    .top-navbar{
        .navbar{
            &-left{
                .call{
                    position: absolute;
                    top:0;
                    left:0;
                    width:100%;
                    padding: 20px;
                    background: #FFFFFF;
                    box-shadow: 0px 1px 15px rgba(0, 0, 0, 0.1);
                    border-radius: 0px 0px 16px 16px;
                }
            }
        }
    }
}

@media (max-width: 576px) {
    .sub-navbar{
        left:${(props) => props.open ? 0 : "-120%"};
        right:${(props) => props.open ? 0 : "120%"};

        .navbar-item{
            font-size: 18px !important;
        }
        
        .shadow{
            background-color: #FFFFFF;
            width:calc(100% - 300px)
        }
    }
}

@media (max-width: 400px) {
    .sub-navbar{
        .navbar-list{
            width:270px
        }
        
        .shadow{
            width:calc(100% - 270px);
            .close{
                left: 6px;
            }
        }
    }
}

`