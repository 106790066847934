
import React from "react";

export default function Branch() {
  return (
    <div>
      <svg
        width="26"
        height="27"
        viewBox="0 0 26 27"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_9_334)">
          <mask
            id="mask0_9_334"
            style={{ maskType: "alpha" }}
            maskUnits="userSpaceOnUse"
            x="1"
            y="6"
            width="25"
            height="20"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M9.39057 8.59302L23.1338 12.8796L23.6571 22.8659L10.1756 23.5725L9.39057 8.59302Z"
              fill="#555555"
              stroke="white"
              strokeWidth="4"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M9.39057 8.59304L3.16116 13.9264L3.68452 23.9127L10.1756 23.5725M19.1633 23.1015L18.8493 17.1097L14.2769 15.8473L14.6694 23.337M23.6571 22.866L10.1756 23.5725"
              stroke="white"
              strokeWidth="4"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </mask>
          <g mask="url(#mask0_9_334)">
            <path
              d="M0.53587 2.04749L24.503 0.791422L25.759 24.7585L1.79193 26.0146L0.53587 2.04749Z"
              fill="white"
            />
          </g>
        </g>
        <defs>
          <clipPath id="clip0_9_334">
            <rect
              width="24"
              height="24"
              fill="white"
              transform="translate(0.53587 2.04749) rotate(-3)"
            />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
}
