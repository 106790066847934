import React, { FC, useEffect, useRef } from "react";
import { Controller } from "react-hook-form";
import { firstErrorField } from "../input/components/first/First";
import ErrorMessage from "../input/components/error/Error";
import get from "lodash.get";
import styled from "styled-components";

const TextArea = ({
    label = "",
    className = "",
    errors,
    name = "",
    rules = { required: false },
    control,
    FORM_NAMES,
    placeholder,
    ...props
}) => {
    const textareaRef = useRef(null);
    const focus = async () => {
        if (textareaRef.current !== null) {
            if (errors) {
                if (!!get(errors, `${name}`, undefined)) {
                    let key = firstErrorField(FORM_NAMES, errors);

                    if (key === name) {
                        textareaRef?.current?.focus();
                    }
                }
            }
        }
    };

    useEffect(() => {
        focus();
    }, [errors && get(errors, `${name}`)]);

    return (
        <div className={className}>
            <Controller
                name={name}
                control={control}
                rules={rules}
                render={({ field: { ...field } }) => (
                    <TextAreaStyled
                        placeholder={placeholder}
                        {...props}
                        {...field}
                        isError={!!errors && !!get(errors, `${name}`, false)}
                        ref={textareaRef}
                    />
                )}
            />

            {errors && get(errors, `${name}`, undefined) && (
                <ErrorMessage value={get(errors, `${name}`)?.message} />
            )}
        </div>
    );
};

export default TextArea;

const TextAreaStyled = styled.textarea`
        /* background: #F5F5F5;
        border-radius: 16px;
        width: 100%;
        height: 200px;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        padding: 18px 16px;
        color: #232323;
        outline: none;
        border: 1px solid ${(props) => (props.isError ? "red" : "#EDF1FF")};
        &::placeholder{
            color: #BDBDBD;
        } */
        border: none;
  outline: none;
  padding: 15px;
  height: 100px;
  width: 100%;
  font-size: 16px;
  background: #f5f5f5;
  border-radius: 16px;
  color: #232323;
  &::placeholder{
    color: #BDBDBD;
  }
    
`
