import React from 'react'
import Button from '@mui/material/Button'
import styled from 'styled-components'
import { useTranslation } from "react-i18next";


const CustomButton = ({ icon,
  text, variant = "contained",
  style, isEndIcon = true,
  full = false, type = "button", onClick }) => {
  const { t } = useTranslation()
  return (
    <StyledButton variant={variant} >
      <Button
        style={style}
        endIcon={isEndIcon && icon}
        startIcon={!isEndIcon && icon}
        fullWidth={full}
        type={type}
        onClick={onClick}
        variant={variant}
      >
        {t(`${text}`)}
      </Button>
    </StyledButton>
  )
}

export default CustomButton

const StyledButton = styled.div`
    button{
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      color: ${({ variant }) => variant === "outlined" ? "#000000" : "#fff"};
      /* background: ${({ variant }) => variant === "outlined" ? "#fff" : "linear-gradient(45deg, #0065FF 0%, #4E9DEA 100%)"}; */
      padding: 16px 18px;
      border-radius: 16px;
      text-transform: initial;
      border: none;
      outline: none;
      transition: all 0.5s;
      box-shadow: none;

      &:hover{
        /* background: ${({ variant }) => variant === "outlined" ? "#0011ff" : "#0011ff"} !important; */
      }
      
    }
`