import axios from "axios";

const myAxios = axios.create({
    baseURL: "https://ziyokoredu.uz/admin-api/",
})

// myAxios.interceptors.request.use(req => {
//     let token = localStorage.getItem("token")
//     if (token) {
//         req.headers.Authorization = `Bearer ${token}`
//     }
//     return req;
// })

export default myAxios