import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "../pages/home/container/Home";
import Login from "../pages/login/Login";
import Layout from "../layout/Layout";
import Work from "../pages/work/Work";
import Suggest from "../pages/suggest/Suggest";
import ScrollToTop from "../components/ScrollToTop/ScrollToTop";

const Router = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="" element={<Home />} />
        <Route path="/" element={<Layout />}>
          <Route path="course" element={<Login />} />
          <Route path="work" element={<Work />} />
          <Route path="suggest" element={<Suggest />} />
        </Route>
        <Route path="*" element={<Home />} />
      </Routes>
      <ScrollToTop />
    </BrowserRouter>
  );
};

export default Router;
