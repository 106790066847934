import React from 'react'

const Graduate = () => {
    return (
        <div>
            <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M16.6024 8.58628C16.718 10.7933 15.0242 12.6745 12.8172 12.7901C10.6102 12.9058 8.72899 11.2119 8.61333 9.00497L8.67398 8.0605L5.4866 6.6654L12.2938 2.80385L19.4674 5.9327L19.7291 10.9258L18.7305 10.9782L18.495 6.48435L16.4433 7.65333L16.6024 8.58628ZM12.9219 14.7874C17.3358 14.5561 21.0046 16.1563 21.1202 18.3632L21.2249 20.3605L5.24684 21.1979L5.14217 19.2006C5.02651 16.9936 8.50792 15.0187 12.9219 14.7874Z" fill="white" />
            </svg>
        </div>
    )
}

export default Graduate