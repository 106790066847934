const ChevronUp = () => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M15.9998 21.3332C16.3776 21.3332 16.6945 21.2052 16.9505 20.9492C17.2056 20.6941 17.3332 20.3776 17.3332 19.9998V15.7332L18.5332 16.9332C18.7776 17.1776 19.0887 17.2998 19.4665 17.2998C19.8443 17.2998 20.1554 17.1776 20.3998 16.9332C20.6443 16.6887 20.7665 16.3776 20.7665 15.9998C20.7665 15.6221 20.6443 15.3109 20.3998 15.0665L16.9332 11.5998C16.7998 11.4665 16.6554 11.3718 16.4998 11.3158C16.3443 11.2607 16.1776 11.2332 15.9998 11.2332C15.8221 11.2332 15.6554 11.2607 15.4998 11.3158C15.3443 11.3718 15.1998 11.4665 15.0665 11.5998L11.5998 15.0665C11.3554 15.3109 11.2332 15.6221 11.2332 15.9998C11.2332 16.3776 11.3554 16.6887 11.5998 16.9332C11.8443 17.1776 12.1554 17.2998 12.5332 17.2998C12.9109 17.2998 13.2221 17.1776 13.4665 16.9332L14.6665 15.7332V19.9998C14.6665 20.3776 14.7945 20.6941 15.0505 20.9492C15.3056 21.2052 15.6221 21.3332 15.9998 21.3332ZM15.9998 29.3332C14.1554 29.3332 12.4221 28.9829 10.7998 28.2825C9.17762 27.5829 7.7665 26.6332 6.5665 25.4332C5.3665 24.2332 4.41673 22.8221 3.71717 21.1998C3.01673 19.5776 2.6665 17.8443 2.6665 15.9998C2.6665 14.1554 3.01673 12.4221 3.71717 10.7998C4.41673 9.17762 5.3665 7.7665 6.5665 6.5665C7.7665 5.3665 9.17762 4.41628 10.7998 3.71584C12.4221 3.01628 14.1554 2.6665 15.9998 2.6665C17.8443 2.6665 19.5776 3.01628 21.1998 3.71584C22.8221 4.41628 24.2332 5.3665 25.4332 6.5665C26.6332 7.7665 27.5829 9.17762 28.2825 10.7998C28.9829 12.4221 29.3332 14.1554 29.3332 15.9998C29.3332 17.8443 28.9829 19.5776 28.2825 21.1998C27.5829 22.8221 26.6332 24.2332 25.4332 25.4332C24.2332 26.6332 22.8221 27.5829 21.1998 28.2825C19.5776 28.9829 17.8443 29.3332 15.9998 29.3332Z" fill="white" />
    </svg>

  );
};

export default ChevronUp;
