import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import Zoom from "react-reveal/Zoom";
import { useTranslation } from "react-i18next";

import Enter from "../../../../assets/icons/EnterWhite";
import CustomButton from "../../../../components/button/CustomButton";
import Carousel from "../../../../components/carousel/Carousel";

import DefaultImg from "../../../../assets/images/DefaultImg.jpg";

const Responsive = [
  {
    breakpoint: 1250,
    settings: {
      slidesToShow: 3,
      slidesToScroll: 1,
    },
  },
  {
    breakpoint: 1000,
    settings: {
      slidesToShow: 2,
      slidesToScroll: 1,
    },
  },
  {
    breakpoint: 768,
    settings: {
      slidesToShow: 1,
      slidesToScroll: 1,
    },
  },
];

const Courses = ({ course, setCourseName }) => {
  const navigate = useNavigate();
  const { list } = course;
  const { t } = useTranslation();
  return (
    <StyledCourse id="course">
      <div className="container">
        <h1>{t("course")}</h1>
        <Carousel slidesToShow={4} responsive={Responsive}>
          {list.map((item, index) => (
            <div key={index} className="course-wrapper">
              <Zoom>
                <div className="course">
                  <div className="course-title">
                    {item.mark ? item.mark : "ABC"}
                  </div>
                  <div className="course-img">
                    <img
                      src={
                        item.imageUrl
                          ? "https://ziyokoredu.uz/admin-api/" + item.imageUrl
                          : DefaultImg
                      }
                      alt={"courseImg"}
                    />
                  </div>
                  <div className="course-info">
                    <div className="course-name">
                      <p>{t("course_name")}</p>
                      <h3>{item.name}</h3>
                    </div>
                    <div className="course-name">
                      <p>{t("duration")}</p>
                      <h3>{item.duration}</h3>
                    </div>
                  </div>
                  <CustomButton
                    icon={<Enter />}
                    text="course_write"
                    full={true}
                    onClick={() => {
                      navigate("/course");
                      setCourseName(item._id);
                    }}
                  />
                </div>
              </Zoom>
            </div>
          ))}
        </Carousel>
      </div>
    </StyledCourse>
  );
};

export default Courses;

const StyledCourse = styled.div`
  h1 {
    font-weight: 700;
    font-size: 40px;
    line-height: 50px;
    text-align: center;
    margin-bottom: 32px;
  }
  .course-wrapper {
    padding: 12px;
    padding-top: 40px;
  }
  .course {
    background: #ffffff;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
    border-radius: 32px;
    padding: 12px;
    position: relative;

    .course-title {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      top: -36px;
      width: 72px;
      height: 72px;
      background: linear-gradient(45deg, #0065ff 0%, #4e9dea 100%);
      border-radius: 50%;
      color: white;
      font-weight: 700;
      font-size: 22px;
      line-height: 30px;
      font-weight: 500;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .course-img {
      min-height: 200px;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        /* height: 100%;
                object-fit: cover;
                overflow: hidden; */
        border-radius: 24px;
        width: 100%;
      }
    }

    .course-info {
      margin-bottom: 20px;
      .course-name {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 20px;

        p {
          font-weight: 600;
          font-size: 16px;
          line-height: 20px;
        }
        h3 {
          font-weight: 700;
          font-size: 16px;
          line-height: 20px;
        }
      }
    }
  }
  @media (max-width: 576px) {
    .course-wrapper {
      padding: 0 !important;
      padding-top: 20px !important;
    }
    h1 {
      font-size: 32px;
      line-height: 40px;
    }
  }
`;
