import styled from "@emotion/styled";

export const ToTopBtnStyled = styled.button`
  position: fixed;
  bottom: 48px;
  right: 48px;
  background-color: #0065FF;
  border: none;
  width: 56px;
  height: 56px;
  border-radius: 50%;
  display: none;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  z-index: 48;
  svg {
    /* margin-bottom: -2px;/ */
  }

  @media (max-width: 576px) {
    bottom: 20px;
    right: 20px;
  }
`;
