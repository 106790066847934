import React, {useEffect, useRef } from "react";

import styled from 'styled-components'
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { firstErrorField } from "./components/first/First";
import ErrorMessage from "./components/error/Error";
import get from "lodash.get";

const Input = ({
  control,
  name = "",
  rules = { required: false },
  id,
  label = "",
  className = "",
  errors,
  FORM_NAMES,
  ...props
}) => {
  const inputRef = useRef(null);
  const { t } = useTranslation()

  const focus = async () => {
    if (inputRef.current !== null) {
      if (errors) {
        if (!!get(errors, `${name}`, undefined)) {
          let key = firstErrorField(FORM_NAMES, errors);

          if (key === name) {
            inputRef?.current?.focus();
          }
        }
      }
    }
  };

  useEffect(() => {
    focus();
  }, [errors && get(errors, `${name}`, undefined)]);


  return (
    <StyledInput
      className={className}
      isError={!!errors && !!get(errors, `${name}`, undefined)}
    >
      <Controller
        name={name}
        control={control}
        rules={rules}
        render={({ field: { ...field } }) => (
          <div className="input">
            <input
              {...props}
              {...field}
              value={field.value || ""}
              ref={inputRef}
            />

          </div>
        )}
      />

      {errors && get(errors, `${name}`, undefined) && (
        <ErrorMessage value={get(errors, `${name}`)?.message} />
      )}
    </StyledInput>
  );
};

export default Input;


const StyledInput = styled.div`
    input{
        width: 100%;
        background: #F5F5F5;
        border-radius: 16px;
        padding: 16px 18px;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        color: #232323;
        outline: none;
        border: 1px solid ${(props) => (props.isError ? "red" : "#EDF1FF")};
        &::placeholder{
            color: #BDBDBD;
        }
        &:focus{
          border: 1px solid #0065FF;
        }
    }
`