import Ielts1 from "./assets/ielts/Ielts1.jpg"
import Ielts2 from "./assets/ielts/Ielts2.jpg"
import Ielts3 from "./assets/ielts/Ielts3.jpg"
import Ielts4 from "./assets/ielts/Ielts4.jpg"
import Ielts5 from "./assets/ielts/Ielts5.jpg"

import Cefr1 from "./assets/cefr/Cefr1.jpg"
import Cefr2 from "./assets/cefr/Cefr2.jpg"
import Cefr3 from "./assets/cefr/Cefr3.jpg"

import School1 from "./assets/schools/President/School1.jpg"
import School2 from "./assets/schools/President/School2.jpg"
import School3 from "./assets/schools/President/School3.jpg"
import School4 from "./assets/schools/President/School4.jpg"
import Xorazmiy1 from "./assets/schools/Xorazmiy/Xorazmiy1.jpg"
import Xorazmiy2 from "./assets/schools/Xorazmiy/Xorazmiy2.jpg"
import Xorazmiy3 from "./assets/schools/Xorazmiy/Xorazmiy3.jpg"
import Xorazmiy4 from "./assets/schools/Xorazmiy/Xorazmiy4.jpg"
import Ibn_Sino1 from "./assets/schools/Ibn_Sino/Ibn_Sino1.jpg"
import Ibn_Sino2 from "./assets/schools/Ibn_Sino/Ibn_Sino2.jpg"

import Dtm1 from "./assets/dtm/Dtm1.jpg"
import Dtm2 from "./assets/dtm/Dtm2.jpg"
import Dtm3 from "./assets/dtm/Dtm3.jpg"
import Dtm4 from "./assets/dtm/Dtm4.jpg"
import Dtm5 from "./assets/dtm/Dtm5.jpg"
import Dtm6 from "./assets/dtm/Dtm6.jpg"
import Dtm7 from "./assets/dtm/Dtm7.jpg"
import Dtm8 from "./assets/dtm/Dtm8.jpg"
import Dtm9 from "./assets/dtm/Dtm9.jpg"
import Dtm10 from "./assets/dtm/Dtm10.jpg"

export const RESULT = [
    {
        id: 0,
        name: "ielts",
        results: [
            {
                index: 0,
                item: <img src={Ielts1} alt="Ielts" />,
                overall: "7.0",
                listening: "8.0",
                reading: 7.5,
                wrting: 6.5,
                speaking: 6.5
            },
            {
                index: 1,
                item: <img src={Ielts2} alt="Ielts" />,
                overall: "7.0",
                listening: "7.5",
                reading: "7.0",
                wrting: "6.0",
                speaking: 7.5
            },
            {
                index: 2,
                item: <img src={Ielts3} alt="Ielts" />,
                overall: "8.0",
                listening: 8.5,
                reading: "9.0",
                wrting: "7.0",
                speaking: 6.5
            },
            {
                index: 3,
                item: <img src={Ielts4} alt="Ielts" />,
                overall: "7.0",
                listening: 8.0,
                reading: 7.5,
                wrting: 6.5,
                speaking: 6.5
            },
            {
                index: 4,
                item: <img src={Ielts5} alt="Ielts" />,
                overall: "8.0",
                listening: 8.5,
                reading: 8.5,
                wrting: 7.5,
                speaking: 6.5
            },

        ]
    },
    {
        id: 1,
        name: "dtm",
        results: [
            {
                index: 0,
                item: <img src={Dtm1} alt="Dtm" />,
                overall: 160.5,
                place:"Toshkent Davlat Yuridik Universiteti",
                rate: "To'lov shartnoma"
            },
            {
                index: 1,
                item: <img src={Dtm2} alt="Dtm" />,
                overall: 162.3,
                place:"Toshkent Davlat Jahon Tillari Universiteti",
                rate: "To'lov shartnoma"
            },
            {
                index: 2,
                item: <img src={Dtm3} alt="Dtm" />,
                overall: 149.2,
                place:"Toshkent Davlat Moliya Instituti",
                rate: "To'lov shartnoma"
            },
            {
                index: 3,
                item: <img src={Dtm4} alt="Dtm" />,
                overall: 177.3,
                place:"Toshkent Davlat Iqtisodiyot Universiteti",
                rate: "To'lov shartnoma"
            },
            {
                index: 4,
                item: <img src={Dtm5} alt="Dtm" />,
                overall: 166,
                place:"Toshkent Davlat Iqtisodiyot Universiteti",
                rate: "To'lov shartnoma"
            },
            {
                index: 5,
                item: <img src={Dtm6} alt="Dtm" />,
                overall: 179.7,
                place:"Toshkent Davlat Yuridik Universiteti",
                rate: "To'lov shartnoma"
            },
            {
                index: 6,
                item: <img src={Dtm7} alt="Dtm" />,
                overall: 157.6,
                place:"Toshkent Davlat Iqtisodiyot Universiteti",
                rate: "To'lov shartnoma"
            },
            {
                index: 7,
                item: <img src={Dtm8} alt="Dtm" />,
                overall: 162.3,
                place:"Toshkent Davlat Yuridik Universiteti",
                rate: "To'lov shartnoma"
            },
            {
                index: 8,
                item: <img src={Dtm9} alt="Dtm" />,
                overall: 166.6,
                place:"Toshkent Davlat Jahon Tillari Universiteti",
                rate: "To'lov shartnoma"
            },
            {
                index: 9,
                item: <img src={Dtm10} alt="Dtm" />,
                overall: 170.8,
                place:"Toshkent Davlat Jahon Tillari Universiteti",
                rate: "To'lov shartnoma"
            },
        ]
    },
    {
        id: 2,
        name: "school",
        results: [
            {
                index: 0,
                item: <img src={School4} alt="School" />,
                overall: 104.8,
                place:"Prezident Maktabi",
                rate: "1-o'rin",
            },
            {
                index: 1,
                item: <img src={School1} alt="School" />,
                overall: 87.5,
                place:"Prezident Maktabi",
                rate: "3-o'rin",
            },
            {
                index: 2,
                item: <img src={School2} alt="School" />,
                overall: 85,
                place:"Prezident Maktabi",
                rate: "4-o'rin",
            },
            {
                index: 3,
                item: <img src={School3} alt="School" />,
                overall: 84.5,
                place:"Prezident Maktabi",
                rate: "5-o'rin",
            },
            {
                index: 4,
                item: <img src={Xorazmiy1} alt="School" />,
                overall: 104.8,
                place:"Muhammad Al-Xorazmiy nomidagi Maktab",
                rate: "1-o'rin",
            },
            {
                index: 5,
                item: <img src={Xorazmiy4} alt="School" />,
                overall: 103.8,
                place:"Muhammad Al-Xorazmiy nomidagi Maktab",
                rate: "1-o'rin",
            },
            {
                index: 6,
                item: <img src={Xorazmiy2} alt="School" />,
                overall: 100.7,
                place:"Muhammad Al-Xorazmiy nomidagi Maktab",
                rate: "4-o'rin",
            },
            {
                index: 7,
                item: <img src={Xorazmiy3} alt="School" />,
                overall: 100.6,
                place:"Muhammad Al-Xorazmiy nomidagi Maktab",
                rate: "5-o'rin",
            },

            {
                index: 8,
                item: <img src={Ibn_Sino2} alt="School" />,
                overall: 103.8,
                place:"Abu Ali Ibn Sino nomidagi Maktab",
                rate: "1-o'rin",
            },
            {
                index: 9,
                item: <img src={Ibn_Sino1} alt="School" />,
                overall: 91.4,
                place:"Abu Ali Ibn Sino nomidagi Maktab",
                rate: "5-o'rin",
            },

        ]
    },
    {
        id: 3,
        name: "certification",
        results: [
            {
                index: 0,
                item: <img src={Cefr1} alt="Cefr" />,
                overall: 54,
                listening: 50,
                reading: 59,
                wrting: 58,
                speaking: 50
            },
            {
                index: 1,
                item: <img src={Cefr2} alt="Cefr" />,
                overall: 65,
                listening: 68,
                reading: 63,
                wrting: 56,
                speaking: 71
            },
            {
                index: 2,
                item: <img src={Cefr3} alt="Cefr" />,
                overall: 69,
                listening: 78,
                reading: 76,
                wrting: 58,
                speaking: 63
            },

        ]
    },

]

export const categoryOptions = [
    {
        name: "ielts",
        _id: 0,
    },
    {
        name: "dtm",
        _id: 1,
    },
    {
        name: "school",
        _id: 2,
    },
    {
        name: "certification",
        _id: 3,
    },
]

export const FORM_NAMES = {
    type: "type"
}