import React from 'react'

const Arrow = () => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4.94667 5.72667L8 8.78L11.06 5.72667L12 6.66667L8 10.6667L4 6.66667L4.94667 5.72667Z" fill="#454545" />
        </svg>

    )
}

export default Arrow