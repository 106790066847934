import React, { useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import Input from '../../components/input/Input'
import CustomButton from '../../components/button/CustomButton';

import Enter from '../../assets/icons/EnterWhite';
import Student from '../../assets/images/Student.png';
import PhoneInput from '../../components/phoneInput/PhoneInput';
import myAxios from '../../service';
import { MyContext } from '../../context/GlobalContext';
import CustomModal from '../../components/modal/CustomModal';
import { MenuItem, Select } from '@mui/material';



const Login = () => {
    const { state: { course, courseName },
        fn: { getCourses, setCourseName } } = useContext(MyContext)
        const { t } = useTranslation()

    const { list } = course
    const FORM_NAMES = {
        fullName: "fullName",
        courseId: "courseId",
        phoneNumber: "phoneNumber",
    };
    const {
        handleSubmit,
        formState: { errors },
        reset,
        control,
        setError
    } = useForm();

    useEffect(() => {
        getCourses()
    }, [])

    const [open, setOpen] = useState(false)
    const handleClickOpen = (item) => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };


    const handleChange = (event) => {
        setCourseName(event.target.value);
    };


    const login = (data) => {
        if(data.phoneNumber?.replaceAll("-", '').replaceAll(" ", '').length != 13){
            return setError('phoneNumber', {type: 'custom', message: "Telefon raqamni to'liq kiriting"})
        }
        const body = { ...data, courseId: courseName, type: "enrollment" }
        try {
            const res = myAxios.post("/sendMessage", body);
            handleClickOpen()
            reset({
                [FORM_NAMES.name]: "",
                [FORM_NAMES.courseId]: "",
                [FORM_NAMES.number]: "",
            })
            setCourseName(-1)
        } catch (error) {
            console.error(error);
        }
    }

    return (
        <StyledLogin courseName={courseName}>
            <img className='image' alt="Student" src={Student} />
            <CustomModal handleClose={handleClose} open={open} />
            <div className='login'>
                <h1>{t("help")}</h1>
                <p>{t("login")}</p>
                <form onSubmit={handleSubmit(login)}
                    autoComplete="off"
                    noValidate
                >
                    <Input
                        placeholder={t("name")}
                        FORM_NAMES={FORM_NAMES}
                        name={FORM_NAMES.fullName}
                        control={control}
                        errors={errors}
                        rules={{ required: { value: true, message: "Field required" } }}
                    />
                    
                    <Select
                        sx={{ width: "100%", '.MuiOutlinedInput-notchedOutline': { border: 0 }, border:"none" }}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={courseName}
                        onChange={handleChange}
                    >
                        <MenuItem disabled value={-1} >{t("course_name")}</MenuItem>
                        {list.map((item) => <MenuItem value={item._id} >{item.name}</MenuItem>)}
                    </Select>
                    <PhoneInput
                        FORM_NAMES={FORM_NAMES}
                        name={FORM_NAMES.phoneNumber}
                        control={control}
                        errors={errors}
                        rules={{ required: { value: true, message: "Field required" } }}
                    />
                    <CustomButton
                        icon={<Enter />}
                        text="send"
                        full={true}
                        type="submit"
                    />
                </form>
            </div>
        </StyledLogin>
    )
}

export default Login

const StyledLogin = styled.div`
    	.MuiSelect-nativeInput{
            &:focus{
                border: none !important;
                outline: none !important;
            }
        }
     .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary{
                    border-radius: 16px;
                    background: #F5F5F5;
                    font-family: GilroyLight, sans-serif;
                    border:none;
                    outline: none;
                    color: ${({courseName})=>courseName===-1 ? "#BDBDBD" : "#232323"};
                    /* &::placeholder{
                    } */
                    &.Mui-focused:active{
                        border: none !important;
                        outline: none !important;
                    }
                }
`