import React from "react";
import ChevronUp from "../../assets/icons/ChevronUp";
import { ToTopBtnStyled } from "./ScrollToTop.style";

function ScrollToTop() {
  window.onscroll = function () {
    scrollFunction();
  };

  function scrollFunction() {
    const button = document.getElementById("btn-back-to-top");
    if (
      document.body.scrollTop > 200 ||
      document.documentElement.scrollTop > 200
    ) {
      if (button) {
        button.style.display = "flex";
      }
    } else {
      if (button) {
        button.style.display = "none";
      }
    }
  }

  function backToTop() {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }

  return (
    <ToTopBtnStyled
      onClick={backToTop}
      type="button"
      className="btn btn-warning btn-floating btn-md"
      id="btn-back-to-top"
    >
      <ChevronUp />
    </ToTopBtnStyled>
  );
}

export default ScrollToTop;
