import React from 'react'
import styled from 'styled-components'
import Zoom from 'react-reveal/Zoom';
import { useTranslation } from "react-i18next";

import Carousel from '../../../../components/carousel/Carousel'

import Teacherback from "../../../../assets/images/Teacherback.png"
import Paths from "../../../../assets/images/Paths.png"
import Book from "../../../../assets/images/Book.png"
import CustomModal from '../../../../components/modal/CustomModal'
import DefaultImg from "../../../../assets/images/DefaultImg.jpg"
const Responsive = [
    {
        breakpoint: 1250,
        settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
        },
    },
    {
        breakpoint: 1000,
        settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
        },
    },
    {
        breakpoint: 768,
        settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
        },
    },

]

const Teachers = ({ teacher }) => {
    const { list } = teacher
    const { t } = useTranslation()
    const [teacherInfo, setTeacherInfo] = React.useState(null);
    const [open, setOpen] = React.useState(false);
    const handleClickOpen = (item) => {
        setOpen(true);
        setTeacherInfo(item)
    };

    const handleClose = () => {
        setOpen(false);
        setTeacherInfo(null)
    };

    return (
        <StyledTeacher id="teacher">
            <img src={Book} alt={"Book"} className="book" />
            <img src={Paths} alt={"Paths"} className="paths" />

            <CustomModal
                open={open}
                handleClose={handleClose}
            >
                <ModalStyled >
                    <div className='closex' onClick={handleClose} >+</div>
                    <div className='modal-img'>
                        <div className='teacher-img'>
                            <img src={teacherInfo?.imageUrl ? "https://ziyokoredu.uz/admin-api/" + teacherInfo?.imageUrl : DefaultImg} alt={"teacherImg"} className="teacherImg" />
                        </div>
                        <div className='teacher-info' >
                            <h3 className='teacher-name'>{teacherInfo?.fullName}</h3>
                            <p>{teacherInfo?.speciality}</p>
                        </div>
                    </div>
                    <div className='modal-info'>
                        <p>{teacherInfo?.description}</p>
                    </div>
                </ModalStyled>
            </CustomModal>

            <div className='container'>
                <h1>{t("teacher")}</h1>
                <Carousel slidesToShow={4} responsive={Responsive} >
                    {
                        list.map((item, index) => <div key={index} className="teacher-wrapper">
                            <Zoom>
                                <div className='teacher'>
                                    <div className='teacher-img'>
                                        <img src={Teacherback} alt={"Teacherback"} className="teacherback" />
                                        <img src={item.imageUrl ? "https://ziyokoredu.uz/admin-api/" + item.imageUrl : DefaultImg} alt={"teacherImg"} className="teacherImg" />
                                    </div>
                                    <div className='teacher-info' onClick={() => handleClickOpen(item)} >
                                        <h3 className='teacher-name'>{item.fullName}</h3>
                                        <p>{item.speciality}</p>
                                    </div>
                                </div>
                            </Zoom>
                        </div>)
                    }
                </Carousel>
            </div>
        </StyledTeacher>
    )
}

export default Teachers

const StyledTeacher = styled.div`
position: relative;
padding-top: 70px;

.book{
    position: absolute;
    left: 46px;
    top: -30px;
    z-index: -1;
}
.paths{
    position: absolute;
    z-index: -1;
    right: 0;
    top: -50px;
}
h1{
    font-weight: 700;
    font-size: 40px;
    line-height: 50px;
    text-align: center;
    margin-bottom: 40px;
}
.teacher-wrapper{
    padding:12px;
}
    .teacher{
        background: #FFFFFF;
        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
        border-radius: 32px;
        padding:20px;

        .teacher-img{
            height: 280px;
            display:flex;
            align-items: flex-end;
            justify-content: center;
            position: relative;
            padding: 0 16px;

            img.teacherback{
                position: absolute;
                top:0;
                left:0;
                right:0;
                bottom:0;
                width:100%;
                height: 100%;
                max-width: 100%;
                max-height: 100%;
            }

            img.teacherImg{
                position: relative; 
                z-index: 5;
                height: 100%;
                object-fit: cover;
                overflow: hidden;
                max-width: 100%;
                border-radius: 16px 16px 0 0;
            }
        }

        .teacher-info{
            background: linear-gradient(45deg, #0065FF 0%, #4E9DEA 100%);
            box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
            border-radius: 16px;
            padding:8px;
            position: relative;
            z-index: 15;
            cursor:pointer;
            transition: all 0.5s;
            &:hover{
                background:  linear-gradient(45deg, #1565c0 0%, #2E9fff 70%);
            }
            
            .teacher-name{
                font-weight: 600;
                font-size: 24px;
                line-height: 29px;
                text-align: center;
                color: #FFFFFF;
            }
            p{
                font-weight: 600;
                font-size: 12px;
                line-height: 15px;
                text-align: center;
                color: #FFFFFF;
            }
        }
    }

@media (max-width: 576px) {
    padding-top: 40px;
    h1{
        font-size: 32px;
        line-height: 40px;
        margin-bottom: 30px;
    }
    .paths{
        display: none;
    }
}
`

const ModalStyled = styled.div`
    display:flex;
    background: #FFFFFF;
    border-radius: 24px;
    padding:32px;
    width: 650px;
    gap:24px;
    position: relative;
    min-width: 300px;
    
    .closex{
            display: flex;
            position: absolute;
            top:27px;
            right:27px;
            background: linear-gradient(45deg, #0065FF 0%, #4E9DEA 100%);
            border-radius: 50px;
            width:34px;
            height: 34px;
            align-items: center;
            justify-content: center;
            font-size: 30px;
            font-weight: 700;
            cursor: pointer;
            transform: rotate(45deg);
            color:#fff;
            z-index: 8;
    }
    

    .modal-img{
        width: 45%;
        .teacher-img{
            position: relative;
            padding: 0 16px;
            height: 300px;
            display: flex;
            align-items: flex-end;
            justify-content: center;
            
            img.teacherImg{
                width: 100%;
                height: 100%;
                border-radius: 16px 16px 0 0;
                object-fit: cover;
                overflow: hidden;
            }
        }
        
        .teacher-info{
            background: linear-gradient(45deg, #0065FF 0%, #4E9DEA 100%);
            box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
            border-radius: 16px;
            padding:8px;
            position: relative;
            z-index: 15;
            cursor:pointer;
            
            .teacher-name{
                font-weight: 600;
                font-size: 24px;
                line-height: 29px;
                text-align: center;
                color: #FFFFFF;
            }
            p{
                font-weight: 600;
                font-size: 12px;
                line-height: 15px;
                text-align: center;
                color: #FFFFFF;
            }
        }
    }

    .modal-info{
        width: 45%;
        padding-top: 20px;
        p{
            font-weight: 600;
            font-size: 16px;
            line-height: 19px;
        }
    }
    @media (max-width: 768px) {
        width: 500px;
    }
    @media (max-width: 576px) {
        .closex{
            top: 16px;
            right: 16px;
        }
        width: 300px;
        display:block;
        padding: 24px 36px;
        .modal-img{
            width: 100%;
            /* padding: 20px 40px; */
            .teacher-img{
                width:100%;
                padding: 0 20px;
            }
        }
        .modal-info{
            width: 100%;
            margin-top: 24px;
        }
    }
`