import React from 'react'

const PrevIcon = () => {
    return (
        <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.41 1.42L2.83 6L7.41 10.59L6 12L5.24537e-07 6L6 -1.23266e-07L7.41 1.42Z" fill="#0065FF" />
        </svg>

    )
}

export default PrevIcon