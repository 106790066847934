import React from 'react'

const Staff = () => {
    return (
        <div>
            <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M21.2538 17.2769C21.7835 17.2491 22.2805 17.012 22.6354 16.6179C22.9904 16.2237 23.1742 15.7046 23.1464 15.1749L22.5707 4.19C22.543 3.66029 22.3059 3.16331 21.9117 2.80838C21.5175 2.45345 20.9985 2.26965 20.4688 2.29741L9.94323 2.84903C10.3247 3.43988 10.5505 4.11899 10.5872 4.81803L20.5735 4.29467L21.1492 15.2796L12.1615 15.7506L12.2662 17.7479M15.7373 7.55224L15.842 9.5495L9.85021 9.86351L10.5306 22.8457L8.53332 22.9504L8.21931 16.9586L6.22205 17.0633L6.53606 23.055L4.5388 23.1597L4.12012 15.1707L2.62217 15.2492L2.36049 10.256C2.33273 9.72633 2.51653 9.20729 2.87146 8.8131C3.22639 8.41891 3.72337 8.18186 4.25308 8.1541L15.7373 7.55224ZM8.5899 4.9227C8.61767 5.45241 8.43386 5.97145 8.07894 6.36563C7.72401 6.75982 7.22702 6.99687 6.69732 7.02463C6.16761 7.05239 5.64857 6.86859 5.25438 6.51366C4.8602 6.15873 4.62315 5.66175 4.59539 5.13204C4.56763 4.60234 4.75143 4.0833 5.10636 3.68911C5.46128 3.29492 5.95827 3.05787 6.48797 3.03011C7.01768 3.00235 7.53672 3.18615 7.93091 3.54108C8.3251 3.89601 8.56214 4.39299 8.5899 4.9227Z" fill="white" />
            </svg>
        </div>
    )
}

export default Staff