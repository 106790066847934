import React from 'react'

const Student = () => {
    return (
        <div>
            <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6.53281 19.1671L6.32336 15.1706L11.2709 17.6763C12.0192 18.0554 12.8537 18.2318 13.6914 18.1879C14.529 18.144 15.3406 17.8813 16.0451 17.4261L20.7037 14.4181L20.9131 18.4135C20.9173 18.4922 20.9061 18.571 20.8798 18.6454C20.8536 18.7197 20.813 18.7882 20.7603 18.8469L20.758 18.8482L20.7558 18.8519L20.7478 18.8595L20.7216 18.8873L20.6304 18.981C20.5187 19.0899 20.4036 19.1954 20.2853 19.2971C19.8722 19.6502 19.4302 19.968 18.964 20.2472C17.4221 21.1716 15.6757 21.7007 13.88 21.7874C12.0851 21.8888 10.293 21.5452 8.66291 20.787C8.17048 20.558 7.6981 20.2882 7.25075 19.9802C7.08623 19.8666 6.92675 19.7459 6.77276 19.6184L6.74381 19.5934C6.68212 19.5399 6.63169 19.4747 6.59547 19.4015C6.55924 19.3283 6.53794 19.2486 6.53281 19.1671ZM23.8264 10.9716L15.3939 16.4184C14.8655 16.7598 14.2568 16.9568 13.6286 16.9897C13.0003 17.0226 12.3744 16.8904 11.8132 16.606L3.78214 12.5388L4.0733 18.0944C4.08162 18.2533 4.02648 18.409 3.92001 18.5272C3.81353 18.6455 3.66443 18.7166 3.50552 18.7249C3.34661 18.7333 3.1909 18.6781 3.07264 18.5716C2.95438 18.4652 2.88327 18.3161 2.87494 18.1572L2.52952 11.5662C2.5238 11.4582 2.54739 11.3506 2.5978 11.2549C2.6482 11.1592 2.72355 11.0789 2.81586 11.0225L11.2355 5.58356C11.7638 5.2418 12.3725 5.04461 13.0008 5.01168C13.6292 4.97875 14.2551 5.11123 14.8162 5.39591L23.7719 9.93262C23.8661 9.98034 23.9459 10.052 24.0035 10.1405C24.061 10.2289 24.0942 10.331 24.0998 10.4364C24.1053 10.5417 24.0829 10.6467 24.0349 10.7407C23.9869 10.8346 23.915 10.9143 23.8264 10.9716Z" fill="white" />
            </svg>

        </div>
    )
}

export default Student