import styled from "styled-components";
import MaskInput from "react-input-mask";
export const MaskInputStyled = styled(MaskInput)`
  background: #F5F5F5;
  width: 100%;
        border-radius: 16px;
        padding: 16px 18px;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        color: #232323;
        outline: none;
        border: 1px solid ${(props) => (props.isError ? "red" : "#EDF1FF")};
        &::placeholder{
            color: #BDBDBD;
        }

  &::placeholder {
    color: #c3c2c1;
  }
  &:focus {
    border: 1px solid #0065FF;
  }
  &.error {
    border-color: red;
  }
`;
