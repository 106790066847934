import React, { useState } from 'react'
import styled from 'styled-components'
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import CustomButton from '../../components/button/CustomButton';

import Enter from '../../assets/icons/EnterWhite';
import Book from '../../assets/images/Book.png';
import PhoneInput from '../../components/phoneInput/PhoneInput';
import TextArea from '../../components/textarea/Textarea';
import myAxios from '../../service';
import CustomModal from '../../components/modal/CustomModal';

const FORM_NAMES = {
    text: "text",
    phoneNumber: "phoneNumber",
};

const Suggest = () => {
    const {
        handleSubmit,
        formState: { errors },
        reset,
        control,
        setError
    } = useForm();
    const { t } = useTranslation()
    const [open, setOpen] = useState(false)
    const handleClickOpen = (item) => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };


    const suggest = (data) => {
        if(data.phoneNumber?.replaceAll("-", '').replaceAll(" ", '').length != 13){
            return setError('phoneNumber', {type: 'custom', message: "Telefon raqamni to'liq kiriting"})
        }
        const body = { ...data, type: "proposal" }
        try {
            const res = myAxios.post("/sendMessage", body);
            handleClickOpen()
            reset({
                [FORM_NAMES.phoneNumber]: "",
                [FORM_NAMES.text]: ""
            })
        } catch (error) {
            console.error(error);
        }
    }
    return (
        <StyledSuggest>
            <CustomModal handleClose={handleClose} open={open} />
            <img className='image' alt="Student" src={Book} />
            <div className='Suggest'>
                <h1>{t("all_idea")}</h1>
                <p>{t("suggests")}</p>
                <form onSubmit={handleSubmit(suggest)}
                    autoComplete="off"
                    noValidate
                >
                    <TextArea
                        placeholder={t("write")}
                        FORM_NAMES={FORM_NAMES}
                        name={FORM_NAMES.text}
                        control={control}
                        errors={errors}
                        rules={{ required: { value: true, message: "Field required" } }}
                    />
                    <PhoneInput
                        FORM_NAMES={FORM_NAMES}
                        name={FORM_NAMES.phoneNumber}
                        control={control}
                        errors={errors}
                        rules={{ required: { value: true, message: "Field required" } }}
                    />
                    <CustomButton
                        icon={<Enter />}
                        text="send"
                        full={true}
                        type="submit"
                    />
                </form>
            </div>
        </StyledSuggest>
    )
}

export default Suggest

const StyledSuggest = styled.div`
    textarea{
        height: 200px;
    }
    button{
        margin-bottom: 24px;
    }
`