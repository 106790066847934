import styled from "styled-components";

const ErrorMessage = ({ value }) => {
    return <ErrorMessageStyled>{value}</ErrorMessageStyled>;
};

export default ErrorMessage;

const ErrorMessageStyled = styled.div`
  color: red;
  margin-top: 4px;
  font-size: 14px;
  display: block;
  `