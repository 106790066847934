import React from 'react'

const Upload = () => {
    return (
        <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.52185 17H17.5219V15H7.52185V17ZM12.5219 14L16.5219 10L15.1219 8.6L13.5219 10.15V6H11.5219V10.15L9.92185 8.6L8.52185 10L12.5219 14ZM12.5219 22C11.1385 22 9.83852 21.7373 8.62185 21.212C7.40518 20.6873 6.34685 19.975 5.44685 19.075C4.54685 18.175 3.83452 17.1167 3.30985 15.9C2.78452 14.6833 2.52185 13.3833 2.52185 12C2.52185 10.6167 2.78452 9.31667 3.30985 8.1C3.83452 6.88333 4.54685 5.825 5.44685 4.925C6.34685 4.025 7.40518 3.31233 8.62185 2.787C9.83852 2.26233 11.1385 2 12.5219 2C13.9052 2 15.2052 2.26233 16.4219 2.787C17.6385 3.31233 18.6969 4.025 19.5969 4.925C20.4969 5.825 21.2092 6.88333 21.7339 8.1C22.2592 9.31667 22.5219 10.6167 22.5219 12C22.5219 13.3833 22.2592 14.6833 21.7339 15.9C21.2092 17.1167 20.4969 18.175 19.5969 19.075C18.6969 19.975 17.6385 20.6873 16.4219 21.212C15.2052 21.7373 13.9052 22 12.5219 22Z" fill="white" />
        </svg>

    )
}

export default Upload