import React from 'react'
import styled from 'styled-components'
import { useNavigate, Link } from "react-router-dom"
import { useTranslation } from "react-i18next";

import Logo from "../../assets/images/logo2.png"

import Email from "./assets/Email.svg"
import Call from "./assets/Call.svg"
import Instagram from "./assets/Instagram.svg"
import Telegram from "./assets/Telegram.svg"
import Facebook from "./assets/Facebook.svg"

const Footer = () => {
    const navigate = useNavigate()
    const { t } = useTranslation()
    return (
        <StyledFooter>

            <div className='container'>
                <div className='footer'>
                    <div className='footer-item'>
                        <img src={Logo} alt="Logo" className='logo' />
                        <div className='info'>
                            <img src={Call} alt="Call" />
                            <a href='tel:+998 69 210-10-10'>+998 69 210-10-10</a>
                        </div>
                        <div className='info'>
                            <img src={Email} alt="Email" />
                            <a href='https://mail.google.com/mail/u/0/#search/ziyokoreducation%40gmail.com' target={"_blank"} rel="noreferrer" >ziyokoreducation@gmail.com</a>
                        </div>
                        <div className='foot-media'>
                            <a target={"_blank"} rel="noreferrer" href="https://t.me/ziyokor_education_uz " >
                                <img src={Telegram} alt="Telegram" />
                            </a>
                            <a target={"_blank"} rel="noreferrer" href="https://www.instagram.com/ziyokor_edu/" >
                                <img src={Instagram} alt="Instagram" />
                            </a>
                            <a target={"_blank"} rel="noreferrer" href="https://www.facebook.com/ziyokor.markazi" >
                                <img src={Facebook} alt="Facebook" />
                            </a>
                        </div>
                    </div>
                    <div className='footer-item'>
                        <ul>
                            <li>
                                <a href="#about_us" >{t("about_us")}</a>
                            </li>
                            <li>
                                <a href="#branch" >{t("branch")}</a>
                            </li>
                            <li>
                                <a href="#result" >{t("result")}</a>
                            </li>
                            <li>
                                <a href="#test" >{t("test_result")}</a>
                            </li>
                        </ul>
                    </div>
                    <div className='footer-item'>
                        <ul>
                            <li>
                                <a href="#news" >{t("ziyo_news")}</a>
                            </li>
                            <li><Link to={"/work"}>{t("lets_work")}</Link>
                            </li>
                            <li><Link to={"/suggest"}>{t("director_msg")}</Link>
                            </li>
                            <li><Link to={"/course"}>{t("login")}</Link>
                            </li>
                        </ul>
                    </div>
                    <div className='footer-media'>
                        <ul>
                            <li>
                                <a target={"_blank"} rel="noreferrer" href="https://t.me/ziyokor_education_uz " >
                                    <img src={Telegram} alt="Telegram" />
                                </a>
                            </li>
                            <li>
                                <a target={"_blank"} rel="noreferrer" href="https://www.instagram.com/ziyokor_edu/" >
                                    <img src={Instagram} alt="Instagram" />
                                </a>
                            </li>
                            <li>
                                <a target={"_blank"} rel="noreferrer" href="https://www.facebook.com/ziyokor.markazi" >
                                    <img src={Facebook} alt="Facebook" />
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
                <p className='all'>{t("all_rights")} &copy; 2022</p>
            </div>
        </StyledFooter>
    )
}

export default Footer

const StyledFooter = styled.div`
    box-shadow: 0px -1px 15px rgba(0, 0, 0, 0.1);
    border-radius: 32px 32px 0px 0px;
    padding: 32px 0 0px;
    margin-top: 60px;

    .all{
        text-align: center;
        padding: 40px 0 20px;
    }

    .footer-media{
        display:none;
    }

    .footer{
        display: flex;
        justify-content: space-between;

        .footer-item{
            .logo{
                width: 130px;
            }
            .foot-media{
                display: flex;
                align-items: center;
                gap: 12px;
                margin-top: 16px;
                a{
                    display:flex;
                    background: linear-gradient(45deg, #0065FF 0%, #4E9DEA 100%);
                    text-decoration: none;
                    align-items: center;
                    justify-content: center;
                    width:36px;
                    height:36px;
                    border-radius: 50%;
                    margin-bottom: 16px;
                }
            }
            .info{
                display: flex;
                align-items: center;
                gap: 10px;
                margin-top: 16px;
                a{
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 20px;
                    color: #232323;
                    text-decoration: none;
                }
            }

            ul{
                list-style: none;
                li{
                    margin-bottom: 16px;
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 20px;
                    cursor: pointer;
                }
                a{
                    color: #454545;
                    text-decoration: none;
                }
            }
        }
    }

    @media (max-width: 768px) {
        padding: 16px 0px 0;
        .footer-media{
            display:block;
            li{
                list-style: none;
            }
            a{
                display:flex;
                background: linear-gradient(45deg, #0065FF 0%, #4E9DEA 100%);
                text-decoration: none;
                align-items: center;
                justify-content: center;
                width:36px;
                height:36px;
                border-radius: 50%;
                margin-bottom: 16px;
            }
        }
        .footer-item{
            display:none;
            &:first-child{
                display: block;
                .foot-media{
                    display: none;
                }
                .info{
                    margin-top: 20px;
                }
            }
            
        }
    }

`