import React, { createContext, useState } from 'react'
import myAxios from '../service'

export const MyContext = createContext()

export default function GlobalContext({ children }) {


    const [branch, setBranch] = useState({
        count: 0,
        list: [],
    })

    async function getBranchs() {
        setBranch((p) => ({
            ...p
        }))
        try {
            const res = await myAxios.post("/branch/pagin");
            setBranch((p) => ({
                ...p,
                count: res.data.data.total,
                list: res.data.data.data,
            }))
            

        } catch (error) {
            console.error(error);
        }
    }

    const [course, setCourse] = useState({
        count: 0,
        list: [],
    })

    async function getCourses() {
        setCourse((p) => ({
            ...p
        }))
        try {
            const res = await myAxios.post("/course/pagin");
            setCourse((p) => ({
                ...p,
                count: res.data.data.total,
                list: res.data.data.data,
            }))
            

        } catch (error) {
            console.error(error);
        }
    }

    const [teacher, setTeacher] = useState({
        count: 0,
        list: [],
    })

    async function getTeachers() {
        setTeacher((p) => ({
            ...p
        }))
        try {
            const res = await myAxios.post("/teacher/pagin");
            setTeacher((p) => ({
                ...p,
                count: res.data.data.total,
                list: res.data.data.data,
            }))
            

        } catch (error) {
            console.error(error);
        }
    }
    const [news, setNews] = useState({
        count: 0,
        list: [],
    })

    async function getNews() {
        setNews((p) => ({
            ...p
        }))
        try {
            const res = await myAxios.post("/project/pagin");
            setNews((p) => ({
                ...p,
                count: res.data.data.total,
                list: res.data.data.data,
            }))
            

        } catch (error) {
            console.error(error);
        }
    }

    const [test, setTest] = useState({
        count: 0,
        list: [],
    })

    async function getTests() {
        setTest((p) => ({
            ...p
        }))
        try {
            const res = await myAxios.post("/test-result/pagin");
            setTest((p) => ({
                ...p,
                count: res.data.data.total,
                list: res.data.data.data,
            }))
            

        } catch (error) {
            console.error(error);
        }
    }

    function uploadTest(fileUrl, fileName) {
        try {
            const token = localStorage.getItem("token");
            let headers = new Headers();
            headers.append("Authorization", `Bearer ${token}`)
            let file = (`https://ziyokoredu.uz/admin-api/${fileUrl}`);
            fetch(file, headers)
                .then((response) => response.blob())
                .then((blobby) => {
                    let objectUrl = window.URL.createObjectURL(blobby);

                    let anchor = document.createElement("a");
                    anchor.style.display = "none"
                    document.body.appendChild(anchor);

                    anchor.href = objectUrl;
                    anchor.download = `${fileName}`;
                    anchor.click();

                    window.URL.revokeObjectURL(objectUrl);
                    document.body.removeChild(anchor);
                });
        } catch (error) {
            console.error(error);
        }
    }

    const [courseName, setCourseName] = useState(-1)  
    const [lang, setLang] = React.useState(localStorage.getItem("i18nextLng"));  

    return (
        <MyContext.Provider value={{
            state: { branch, course, teacher, news, test, courseName, lang },
            fn: { getBranchs, getCourses, getTeachers, getNews, getTests, uploadTest, setCourseName, setLang }
        }}>
            {children}
        </MyContext.Provider>
    )

}