import React from 'react'
import styled from 'styled-components'
import Upload from '../../../../assets/icons/Upload'
import CustomButton from '../../../../components/button/CustomButton'
import Carousel from '../../../../components/carousel/Carousel'
import Zoom from 'react-reveal/Zoom';
import { useTranslation } from "react-i18next";


const Responsive = [
    {
        breakpoint: 1250,
        settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
        },
    },
    {
        breakpoint: 1000,
        settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
        },
    },
    {
        breakpoint: 768,
        settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
        },
    },

]

const Test = ({ test, uploadTest }) => {
    const { list } = test
    const { t } = useTranslation()
    return (
        <StyledTest id="test">

            <div className='container'>
                <h1>{t("test_result")}</h1>
                <Carousel slidesToShow={4} responsive={Responsive} >
                    {
                        list.map((item, index) => <div key={index} className="test-wrapper">
                            <Zoom>
                                <div className='test'>
                                    <div className='test-info'>
                                        <div className='test-result'>
                                            <p>{t("course_name")}</p>
                                            <h3 className='test-name'>{item.title}</h3>
                                        </div>
                                        <div className='test-result'>
                                            <p>{t("time")}</p>
                                            <h3 className='test-name'>{item.createdAt.split("T")[0].split("-").reverse().join(".")}</h3>
                                        </div>
                                    </div>
                                    <CustomButton
                                        text={"download"}
                                        icon={<Upload />}
                                        full={true}
                                        onClick={() => uploadTest(item.file.url, item.file.name)}
                                    />
                                </div>
                            </Zoom>
                        </div>)
                    }
                </Carousel>
            </div>
        </StyledTest>
    )
}

export default Test

const StyledTest = styled.div`
margin-top: 60px;
h1{
    font-weight: 700;
    font-size: 40px;
    line-height: 50px;
    text-align: center;
    margin-bottom: 40px;
}
.test-wrapper{
    padding:12px;
}
    .test{
        background: #FFFFFF;
        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
        border-radius: 24px;
        padding:12px;

        .test-info{
            margin-bottom: 24px;

            .test-result{
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-top: 14px;
                .test-name{
                    font-weight: 700;
                    font-size: 16px;
                    line-height: 20px;
                }
                p{
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 20px;
                }
            }
            
        }
    }
@media (max-width: 576px) {
    margin-top: 40px;
    .test-wrapper{
        padding: 0;
    }
    h1{
        font-size: 32px;
        line-height: 40px;
        margin-bottom: 30px;
    }
}
`