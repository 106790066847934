import React from "react";
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styled from "styled-components";

import PrevIcon from "../../assets/icons/PrevIcon";

const Carousel = ({
  children,
  slidesToShow = 3,
  responsive,
  customSettings,
  className,
}) => {
  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: slidesToShow,
    slidesToScroll: 1,
    responsive: responsive,
    ...customSettings,
  };
  return (
    <StyledCarousel slide={slidesToShow} className={className}>
      <div className="prev">
        <PrevIcon />
      </div>
      <Slider {...settings}>{children}</Slider>
      <div className="next">
        <PrevIcon />
      </div>
    </StyledCarousel>
  );
};

export default Carousel;

const StyledCarousel = styled.div`
  position: relative;
  .branch-carousel {
    .prev,
    .next {
      display: none;
    }
  }
  .prev {
    position: absolute;
    left: -7px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 8;
    pointer-events: none;
  }
  .next {
    position: absolute;
    right: -6px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 8;
    pointer-events: none;
    svg {
      transform: rotate(180deg);
    }
  }
  .slick-slide {
    padding: ${(props) => (props.slide === 1 ? "0 32px" : "0")};
  }
  .slick-slider.slick-initialized {
    .slick-next,
    .slick-prev {
      border-radius: 50%;
      width: 48px;
      height: 48px;
      background: white;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
      z-index: 5;
      transition: all 0.5s;
      &:hover {
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
      }
      &::before {
        opacity: 1 !important;
        font-size: 25px;
        border-radius: 50%;
        color: white;
        content: none;
      }
    }
    .slick-prev {
      &::before {
        /* content: ${(<PrevIcon />)}; */
      }
    }
  }
  @media (max-width: 576px) {
    .prev {
      left: 9px;
    }
    .next {
      right: 9px;
    }
    .slick-next {
      right: -10px;
    }
    .slick-prev {
      left: -10px;
    }
    .slick-slide {
      padding: 16px;
    }
  }
`;
