import React, { useContext, useEffect } from 'react'
import Footer from '../../../components/footer/Footer'
import ScrollNavbar from '../../../components/navbar/components/ScrollNavbar'
import Navbar from '../../../components/navbar/container/Navbar'
import { MyContext } from '../../../context/GlobalContext'

import About from '../sections/about/About'
import Branch from '../sections/branchs/Branch'
import Courses from '../sections/courses/Courses'
import Main from '../sections/main/Main'
import News from '../sections/news/News'
import Result from '../sections/results/Result'
import Teachers from '../sections/teachers/Teachers'
import Test from "../sections/test_result/Test"

const Home = () => {
  const { state: { branch, course, teacher, news, test },
    fn: { getBranchs, getCourses, getTeachers, getNews, getTests, uploadTest, setCourseName } } = useContext(MyContext)

  useEffect(() => {
    getBranchs()
    getCourses()
    getTeachers()
    getNews()
    getTests()
  }, [])

  return (
    <div>
      <Navbar />
      <ScrollNavbar/>
      <Main />
      <About />
      <Branch branch={branch} />
      <Courses course={course} setCourseName={setCourseName} />
      <Result/>
      <Teachers teacher={teacher} />
      <News news={news} />
      <Test test={test} uploadTest={uploadTest} />
      <Footer />
    </div>
  )
}

export default Home