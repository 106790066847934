import React from 'react'
import styled from 'styled-components'
import Zoom from 'react-reveal/Zoom';
import { useTranslation } from "react-i18next";
import Carousel from '../../../../components/carousel/Carousel'

import DefaultImg from "../../../../assets/images/DefaultImg.jpg"
const Responsive = [
    {
        breakpoint: 1000,
        settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
        },
    },
    {
        breakpoint: 768,
        settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
        },
    }
]


const News = ({ news }) => {
    const { list } = news
    const { t } = useTranslation()
    return (
        <StyledNews id="news">
            <div className='container'>
                <h1>{t("news")}</h1>
                <Carousel slidesToShow={3} responsive={Responsive}>
                    {
                        list.map((item, index) => <div key={index} className="news-wrapper">
                            <Zoom>
                                <div className='news'>
                                    <div>
                                        <div className='news-img'>
                                            <img src={item.imageUrl ? "https://ziyokoredu.uz/admin-api/" + item.imageUrl : DefaultImg} alt={"newsImg"} className="newsImg" />
                                        </div>
                                        <div className='news-info'>
                                            <h3 className='news-name'>{item.title.length < 25 ? item.title : item.title.slice(0, 25) + "..."}</h3>
                                            <p>{item.description.length < 150 ? item.description : item.description.slice(0, 150) + "..."}</p>
                                        </div>
                                    </div>
                                    <a href={item.link} target="_blank" rel="noreferrer">{t("more_read")}</a>
                                </div>
                            </Zoom>
                        </div>)
                    }
                </Carousel>
            </div>
        </StyledNews>
    )
}

export default News

const StyledNews = styled.div`
margin-top: 70px;
h1{
    font-weight: 700;
    font-size: 40px;
    line-height: 50px;
    text-align: center;
    margin-bottom: 50px;
}
.news-wrapper{
    padding:12px;
}
    .news{
        background: #FFFFFF;
        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
        border-radius: 32px;
        padding:12px;
        min-height: 340px !important;
        display:flex;
        flex-direction: column;
        justify-content: space-between;

        .news-img{
            min-height: 200px;
            display:flex;
            align-items: center;
            justify-content: center;
            img.newsImg{
                /* height: 100%;
                object-fit: cover;
                overflow: hidden; */
                width: 100%;
                border-radius:16px;
            }
        }

        a{
            font-weight: 500;
            font-size: 14px;
            line-height: 17px;
            text-decoration: none;
            color: #0065FF;
        }
        .news-info{
            margin-top: 12px;
            
            .news-name{
                font-weight: 600;
                font-size: 20px;
                line-height: 25px;
            }
            p{
                font-weight: 500;
                font-size: 14px;
                line-height: 17px;
                margin: 12px 0;
            }
        }
    }
@media (max-width: 576px) {
    margin-top: 40px;
    h1{
        font-size: 32px;
        line-height: 40px;
        margin-bottom: 30px;
    }
    .news-wrapper{
        padding: 0;
    }
}
`