import React, { useState } from 'react'
import styled from 'styled-components'
import { Outlet, useNavigate } from "react-router-dom"

import CustomButton from '../components/button/CustomButton'


import Uzb from "../assets/icons/Uzb.svg"
import Russia from "../assets/icons/Russia.svg"
import English from "../assets/icons/English.svg"
import Enter from "../assets/icons/Enter"
import Saly from "../assets/images/Saly.png"
import Logo from "../assets/images/logo2.png"
import Pattern from "../assets/images/Pattern.png"
import Telephone from '../assets/icons/Telephone'

const Layout = () => {
    const navigate = useNavigate();

    const handleChange = (value) => {
        localStorage.setItem("i18nextLng", `${value}`)
        window.location.reload();
    };

    return (
        <StyledLayout>
            <div className='layout'>

                <div className='layout-left'>
                    <CustomButton
                        icon={<Enter left={true} />}
                        isEndIcon={false}
                        text="return"
                        // variant='outlined'
                        onClick={() => navigate("/")}
                        style={{
                            background: "#fff",
                            color: "#000",
                        }}
                    />

                    <div className='layout-img'>
                        <img src={Saly} alt="Saly" />
                    </div>
                    <div className="layout-info">
                        <a className='tel' href="tel: +998 69 210-10-10">
                            <Telephone />
                            +998 69 210-10-10
                        </a>
                        <div className='layout-lang'>
                            <img src={Uzb} alt="Uzb" onClick={() => handleChange("uz")} />
                            <img src={Russia} alt="Russia" onClick={() => handleChange("ru")} />
                            <img src={English} alt="English" onClick={() => handleChange("en")} />
                        </div>
                    </div>
                </div>
                <div className='layout-right'>
                    <div className='form'>
                        <img src={Logo} alt={"Logo"} className='logo' />
                        <Outlet />
                    </div>
                    <a className='tel' href="tel: +998 69 210-10-10">
                        <Telephone />
                        +998 69 210-10-10
                    </a>
                    <img className='pattern' src={Pattern} alt={"Pattern"} />
                </div>
            </div>
        </StyledLayout>
    )
}

export default Layout

const StyledLayout = styled.div`
    height: 100vh;
    position: relative;
    .logo{
       max-width: 130px;
    }
    .layout{
        display: flex;
        height: 100%;

        &-left{
            width: 55%;
            padding: 24px;
            background: linear-gradient(45deg, #0065FF 0%, #4E9DEA 100%);
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            button:hover{
                background-color: #fff !important;
            }

            .layout-img{
                display: flex;
                justify-content: center;
            }

            .layout-info{
                display: flex;
                gap: 12px;
                align-items: center;
                .tel{
                    background: rgba(255, 255, 255, 0.15);
                    border-radius: 16px;
                    padding: 13px;
                    display: flex;
                    align-items: center;
                    gap: 12px;
                    text-decoration: none;
                    font-weight: 600;
                    font-size: 16px;
                    color: #FFFFFF;
                }
                .layout-lang{
                    background: rgba(255, 255, 255, 0.15);
                    border-radius: 16px;
                    padding: 13px;
                    display: flex;
                    align-items: center;
                    gap: 8px;

                    img{
                        cursor: pointer;
                    }
                }
            }
        }
        &-right{
            width: 45%;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0px 105px;
            .tel{
                display:none;
            }

            .pattern{
                position: absolute;
                right: 0;
                bottom: 0;
                z-index: -1;
            }

            .image{
                position: absolute;
                right: 35px;
                top: 35px;
            }

            form{
                margin-top: 24px;
                display: flex;
                flex-direction: column;
                gap: 24px;
                min-width: 300px;
                
            }
            h1{
                font-weight: 600;
                font-size: 36px;
                line-height: 44px;
                margin: 8px 0;
            }
            p{
                font-weight: 500;
                font-size: 24px;
                line-height: 29px;
            }
        }
    }
@media (max-width: 992px) {
    .layout{
        .layout-right{  
            padding: 16px;
        }
    }
}
@media (max-width: 768px) {
    .layout{
        .layout-right{ 
            width: 100%; 
            flex-direction: column;
            justify-content: space-between;
            padding: 80px 50px 16px;
            .tel{  
                    background: rgba(255, 255, 255, 0.15);
                    border-radius: 16px;
                    margin-bottom: 16px;
                    padding: 13px;
                    display: flex !important;
                    align-items: center;
                    gap: 12px;
                    text-decoration: none;
                    font-weight: 600;
                    font-size: 16px;
                    background: rgba(0, 101, 255, 0.05);
                    backdrop-filter: blur(1.5px);
                    border-radius: 16px;
                    color: #0065FF;
                    svg{
                        path{
                            fill: #0065FF;
                        }
                    }
                }
        }
        .layout-left{  
            display:none;
        }
    }
}
@media (max-width: 576px) {
    .layout{
        .layout-right{
            padding: 60px 16px 16px;
            h1{
                font-size: 32px;
                line-height: 36px;
            }
            p{
                font-size: 20px;
                line-height: 26px;
            }
            textarea{
                height: 150px;
            }
        }
    }
}
`
