import React, { useState } from 'react'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from "react-i18next";


import Fade from 'react-reveal/Fade';

import CustomModal from '../../../../components/modal/CustomModal'
import CustomButton from '../../../../components/button/CustomButton'

import Image1 from "./assets/images/Image1.png"
import Image2 from "./assets/images/Image2.png"
import Image3 from "./assets/images/Image3.png"
import Image4 from "./assets/images/Image4.png"
import Pattern1 from "./assets/images/Pattern1.png"
import Pattern2 from "./assets/images/Pattern2.png"
import Enter from "../../../../assets/icons/EnterWhite"
import Video from "./assets/icons/Video.js"
import Down from "../../../../assets/icons/Down.svg"
import Close from '../../../../assets/icons/Close';

const Main = () => {
    const navigate = useNavigate()
    const [open, setOpen] = useState(false)
    const URL = "https://www.youtube.com/embed/ogu5pJkeo04"
    const [iframeSrc, setIframeSrc] = useState(URL)
    const { t } = useTranslation()
    const handleClickOpen = (item) => {
        setOpen(true);
        setIframeSrc(URL)
    };

    const handleClose = () => {
        setOpen(false);
        setIframeSrc('')
    };
    return (
        <StyledMain>
            <div className='top container'>
                <Fade left>
                    <div className='main-img'>
                        <img src={Image1} alt="Image1" />
                    </div>
                </Fade>
                <div className='main-text'>
                    <h1>{t("main_label")}</h1>
                    <p>{t("main_text")}</p>
                </div>
                <Fade right>
                    <div className='main-img'>
                        <img src={Image2} alt="Image2" />
                    </div>
                </Fade>
            </div>

            <CustomModal open={open} handleClose={handleClose} >
                <span className='close' onClick={handleClose}><Close /></span>
                <iframe width="560" height="315" src={iframeSrc} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
            </CustomModal>

            <div className='container'>
                <div className='bottom'>
                    <Fade left>
                        <div className='main-img'>
                            <img src={Image3} alt="Image3" />
                        </div>
                    </Fade>
                    <div className='main-text'>
                        <CustomButton variant='contained' onClick={() => navigate("/course")} icon={<Enter />} text="login" />
                        <CustomButton
                            onClick={handleClickOpen}
                            style={{
                                background: "rgba(0, 101, 255, 0.1)",
                                color: "#0065FF",
                                padding: "18px"
                            }}
                            icon={<Video />} text="video" />
                    </div>
                    <Fade right>
                        <div className='media-img'>
                            <img src={Image4} alt="Image4" />
                        </div>
                    </Fade>
                </div>
                <div className='down'>
                    <a href="#branch"> <img src={Down} alt="Down" /> </a>
                </div>
            </div>
            <img src={Pattern1} alt="Pattern1" className='pattern1' />
            <img src={Pattern2} alt="Pattern2" className='pattern2' />

        </StyledMain>
    )
}

export default Main

const StyledMain = styled.div`
position: relative;
.pattern1{
    position: absolute;
    left:0;
    bottom: 0;
    z-index: -1;
    max-width: 100%;
}
.pattern2{
    position: absolute;
    right:0;
    bottom: -350px;
    z-index: -1;
    max-width: 100%;
}

.down{
    display:flex;
    justify-content: center;
    margin-top: 35px;
    &>a{
        background: linear-gradient(45deg, #0065FF 0%, #4E9DEA 100%);
        width: 56px;
        height: 56px;
        border-radius: 50%;
        display:flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        &:hover{
            background: linear-gradient(135deg, #0065FF 0%, #4E9DEA 100%);
        }
    }
}

    .top{
        display: flex;
        padding: 0 54px;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        gap: 24px;
        margin-top: 20px;

        .main-text{
            text-align: center;
            h1{
                font-weight: 700;
                font-size: 40px;
                line-height: 50px;
            }
            p{
                font-weight: 600;
                margin-top: 32px;
            }

        }
    }
    .bottom{
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        gap: 24px;
        margin-top: 32px;
        .main-text{
            display: flex;
            align-items: center;
            gap:24px;
        }
    }
    @media (max-width: 1200px) {
        .bottom{
            .main-text{
                display: block;
                text-align: center;
                button{
                    margin-top: 12px;
                }
            }
        }
    }

    @media (max-width: 992px) {
        .top{
            .main-img{
                display: none;
            }
        }
        .bottom{
            display:block;
            text-align: center;
            .main-text{
                justify-content: center;
                margin-bottom: 24px;
            }
            .main-img{
                display: none;
            }
            .media-img{
                width:70%;
                margin: 0 auto;
                img{
                    width: 100%;
                }
            }
        }
    }
    @media (max-width: 576px) {
        iframe{
            width: 300px !important;
            height: 200px !important;
        }
        .top{
            padding: 0 16px;
            h1{
                font-size: 32px !important;
                line-height: 40px !important;
            }
        }
        .bottom{
            .main-text{
                display: block;

                button{
                    margin-top: 12px;
                }
            }
            .media-img{
                width:100%;
            }
        }
    }
`