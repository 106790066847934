import React, { useState } from 'react'
import styled from 'styled-components'
import Zoom from 'react-reveal/Zoom';
import { useTranslation } from "react-i18next";


import Carousel from '../../../../components/carousel/Carousel'

import { categoryOptions, RESULT } from './Result.constant'
import { MenuItem, Select } from '@mui/material';
import CustomModal from '../../../../components/modal/CustomModal';

const Result = () => {
    const [resultType, setResultType] = useState(0)
    const [results, setResults] = useState([])
    const { t } = useTranslation()
    const handleChange = (id) => {
        setResultType(id)
        const newResult = RESULT.filter((item) => item.id === id)
        setResults(newResult[0].results)
    }
    const changeValue = (event) => {
        const newResult = RESULT.filter((item) => item.id === event.target.value)
        setResults(newResult[0].results)
        console.log(event.target)
        setResultType(event.target.value)
    }

    const [open, setOpen] = React.useState(false);
    const [image, setImage] = React.useState(null);
    const handleClickOpen = (item) => {
        setOpen(true);
        setImage(item)
    };

    const handleClose = () => {
        setOpen(false);
        setImage(null)
    };
    return (
        <StyledResult id="result">
            <div className='container'>
                <div className='result-wrapper'>
                    <h1>{t("result")}</h1>
                    <CustomModal handleClose={handleClose} open={open} >
                        {image}
                    </CustomModal>
                    <div className='result'>
                        <div className='result-left'>
                            {
                                RESULT.map((item, index) => <div key={index}
                                    className={item.id === resultType ? `active` : ""}
                                    onClick={() => handleChange(item.id)} >
                                    {t(`${item.name}`)}
                                </div>)
                            }
                        </div>
                        <div className='select'>
                            <Select
                                sx={{ minWidth: 120, '.MuiOutlinedInput-notchedOutline': { border: 0 }, boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)" }}
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={resultType}
                                onChange={changeValue}
                            >
                                {categoryOptions?.map((item) => <MenuItem value={item._id}> {t(`${item.name}`)}</MenuItem>)}
                            </Select>
                        </div>
                        <div className='result-right'>
                            <Carousel slidesToShow={1}>

                                {
                                    results.length ? ((resultType === 2 || resultType === 1) ?
                                        results?.map((i, idx) => <div key={idx} className="result-wrap">
                                            <div className='result-image'  onClick={() => handleClickOpen(i.item)}  >{i.item}</div>
                                            <div className='result-info'>
                                                <div className='result-ball'>
                                                    <h2 className='overall'>Umumiy</h2>
                                                    <p className='overall' >{i.overall} <span>ball</span></p>
                                                </div>
                                                <div className='result-place'>
                                                    <p>{i.place}</p>
                                                    <h2>{i.rate}</h2>
                                                </div>
                                            </div>

                                        </div>)
                                        : results?.map((i, idx) => <div key={idx} className="result-wrap">
                                            <div className='result-image'  onClick={() => handleClickOpen(i.item)}  >{i.item}</div>
                                            <div className='result-info'>
                                                <div className='result-ball'>
                                                    <h2 className='overall'>Overall</h2>
                                                    <p className='overall' >{i.overall}</p>
                                                </div>
                                                <div className='result-ball'>
                                                    <h2>LISTENING:</h2>
                                                    <p >{i.listening}</p>
                                                </div>
                                                <div className='result-ball'>
                                                    <h2>READING:</h2>
                                                    <p >{i.reading}</p>
                                                </div>
                                                <div className='result-ball'>
                                                    <h2>WRITING:</h2>
                                                    <p >{i.wrting}</p>
                                                </div>
                                                <div className='result-ball'>
                                                    <h2>SPEAKING</h2>
                                                    <p >{i.speaking}</p>
                                                </div>
                                            </div>

                                        </div>))
                                        : RESULT[0].results?.map((i, idx) =>
                                            <div className="result-wrap">
                                                <div className='result-image' onClick={() => handleClickOpen(i.item)} >{i.item}</div>
                                                <div className='result-info'>
                                                    <div className='result-ball'>
                                                        <h2 className='overall'>Overall</h2>
                                                        <p className='overall' >{i.overall}</p>
                                                    </div>
                                                    <div className='result-ball'>
                                                        <h2>Listening:</h2>
                                                        <p >{i.listening}</p>
                                                    </div>
                                                    <div className='result-ball'>
                                                        <h2>Reading:</h2>
                                                        <p >{i.reading}</p>
                                                    </div>
                                                    <div className='result-ball'>
                                                        <h2>Wrting:</h2>
                                                        <p >{i.wrting}</p>
                                                    </div>
                                                    <div className='result-ball'>
                                                        <h2>Speaking:</h2>
                                                        <p >{i.speaking}</p>
                                                    </div>
                                                </div>

                                            </div>

                                        )
                                }

                            </Carousel>
                        </div>
                    </div>
                </div>
            </div>
        </StyledResult>
    )
}

export default Result

const StyledResult = styled.div`
margin-top: 70px;
    .result-wrapper{
        .select{
            display: none;
        }
        h1{
            font-weight: 700;
            font-size: 40px;
            line-height: 50px;
            text-align: center;
            color: #232323;
            margin-bottom: 30px;
        }

        .result{
            display: flex;
            align-items: center;
            justify-content: center;
            gap:40px;

            .result-left{
                padding:8px;
                width:250px;
                background: #F5F5F5;
                border-radius: 24px;
                font-weight: 600;
                font-size: 16px;
                line-height: 20px;
                text-align: center;
                color: #BDBDBD;
                div{
                    padding: 30px;
                    width: 100%;
                    cursor: pointer;

                    &.active{
                        background: #FFFFFF;
                        box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
                        border-radius: 24px;
                        color: #0065FF;
                    }
                }
            }

            .result-right{
                width: calc(100% - 320px);
                padding: 0 30px;
                .result-wrap{
                    background: #FFFFFF;
                    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.08);
                    border-radius: 24px;
                    padding: 32px;
                    padding-right: 24px;
                    display: flex !important;
                    gap:20px;
                    margin : 20px 0;

                    .result-image{
                        width:300px;
                        height:300px;

                        img{
                            border-radius: 24px;
                            width: 100%;
                            object-fit: cover;
                        }
                    }

                    .result-info{
                        width: calc(100% - 320px);
                        .result-place{
                            p{
                                font-size: 24px;
                                line-height: 28px;
                            }
                            h2{
                                font-size: 32px;
                                line-height: 38px;
                                margin-top: 16px;
                                color: #0065FF;
                            }
                        }
                        .result-ball{
                            display:flex;
                            align-items: center;
                            justify-content: space-between;
                            margin-bottom: 24px;

                            h2{
                                font-size: 24px;
                                line-height: 28px;
                                color: #454545;
                                text-transform: uppercase;
                                &.overall{
                                    font-size: 28px;
                                    line-height: 34px;
                                    color: #232323;
                                }
                            }
                            p{
                                font-size: 24px;
                                line-height: 28px;
                                color: #0065FF;
                                &.overall{
                                    font-weight: 700;
                                    font-size: 40px;
                                    line-height: 48px;
                                    text-align: center;
                                    color: #FFFFFF;
                                    background: linear-gradient(45deg, #0065FF 0%, #4E9DEA 100%);
                                    padding: 5px;

                                    span{
                                        font-size: 24px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    @media (max-width: 1200px) {
        .result-wrapper{
            .result{
                display: block;
                .result-left{
                    width:100%;
                    display: flex;
                    align-items: center;
                }
                .result-right{
                    width:100%;
                }
            }
        }
    }

    @media (max-width: 768px) {
        .result-wrapper{
            .select{
                display: block;
                padding: 0 16px;

                .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary{
                    width:100%;
                    border-radius: 16px;
                }
            }
            .result{
                .result-left{
                    display: none;
                }
                .result-right{
                    padding: 0;
                    .result-wrap{
                        .result-image{
                            width: 150px;
                        }
                        .result-info{
                            width: calc(100% - 150px)
                        }
                    }
                }
            }
        }
    }
    @media (max-width: 576px) {
        margin-top: 40px;
        .result-wrapper{
            h1{
                font-size: 32px;
                line-height: 40px;
            }
           
            .result{
                
                .result-right{
                    padding: 0;
                    .result-wrap{
                        padding: 16px;
                        display: block !important;
                        .result-image{
                            width: 100%;
                            height:initial;
                        }
                        .result-info{
                            width: 100%;
                            display: flex;
                            flex-wrap: wrap;
                            margin-top: 8px;
                            .result-place{
                                h2{
                                    font-size: 24px;
                                    line-height: 28px;
                                }
                                p{
                                    font-size: 16px;
                                    line-height: 20px;
                                }
                            }
                            .result-ball{
                                &:first-child{
                                    width: 100%;
                                    display:flex;
                                    gap: 30px;
                                    justify-content: inherit;
                                }
                                width:50%;
                                display: block;
                                margin-bottom: 10px;
                                h2{
                                    font-size: 16px;
                                    line-height: 20px;
                                    text-transform: capitalize !important;
                                    &.overall{
                                    font-size: 24px;
                                    line-height: 28px;
                                    color: #232323;
                                    text-transform: uppercase !important;
                                }
                                }
                                p.overall{
                                    font-size: 24px;
                                    line-height: 28px; 

                                    span{
                                        font-size: 16px;
                                        line-height: 18px; 
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`