import React from 'react'
import styled from 'styled-components'
import { useTranslation } from "react-i18next";
import Fade from "react-reveal/Fade"


import Logo from "../../../assets/images/logo2.png"
import { useScrollPosition } from '../../../hooks/useScroll'
import { useNavigate, Link } from 'react-router-dom';



const ScrollNavbar = () => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const scrollPosition = useScrollPosition()

    return (
        <StyledScrollNavbar scrollPosition={scrollPosition} id="main" >
            <div className='navbar'>
                <Fade left>
                    <div className='navbar-left'>
                        <a href="#main" className='logo'>
                            <img src={Logo} alt="logo" />
                        </a>
                    </div>
                </Fade>
                <Fade right>
                    <ul className='navbar-list'>
                        <li className='navbar-item'>
                            <a href="#about_us">{t("about_us")}</a>
                        </li>
                        <li className='navbar-item'>
                            <a href="#branch">{t("branch")}</a>
                        </li>
                        <li className='navbar-item'>
                            <a href="#result">{t("result")}</a>
                        </li>
                        <li className='navbar-item'>
                            <a href="#news">{t("ziyo_news")}</a>
                        </li>
                        <li className='navbar-item'>
                            <a href="#teacher">{t("teacher")}</a>
                        </li>
                        <li className='navbar-item'><Link to={"/work"}>{t("lets_work")}</Link>
                        </li>
                        <li className='navbar-item'><Link to={"/suggest"}>{t("director_msg")}</Link>
                        </li>
                    </ul>
                </Fade>
            </div>
        </StyledScrollNavbar>
    )
}

export default ScrollNavbar

const StyledScrollNavbar = styled.div`
    .navbar{
        /* max-width: 2000px; */
        display: ${(props) => props.scrollPosition > 500 ? "flex" : "none"};
        position: fixed;
        top:25px;
        background: #FFFFFF;
        box-shadow: 0px 1px 15px rgba(0, 0, 0, 0.08);
        z-index: 40;
        left: 120px;
        right: 120px;
        border-radius: 24px;
        padding: 16px 25px;
        transition: all 1 s;
        justify-content: space-between;
        align-items: center;

        &-left{
            width:140px;
            .logo{
                img{
                    width: 130px;
                }
            }
        }
        .navbar-list{
            width: calc(100% - 140px);
            display: flex;
            align-items: center;
            justify-content: space-between;
            list-style: none;
            li{
                font-weight: 600;
                font-size: 16px;
                line-height: 20px;
                padding: 0 6px;
            }
            a{
                color: #454545;
                text-decoration: none;

            }
        }

    }
    @media (max-width: 1250px){
        .navbar{
            right: 20px;
            left:20px;
        }
    }

    @media (max-width: 992px){
        display:none;
    }

`