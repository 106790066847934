import * as React from 'react';
import styled from 'styled-components';
import CustomButton from '../button/CustomButton';

import Boy from "../../assets/images/Boy.png"
import { useNavigate } from 'react-router-dom';
import { useTranslation } from "react-i18next";

export default function CustomModal({ open, handleClose, children }) {
    const navigate = useNavigate()
    const { t } = useTranslation()
    React.useEffect(() => {
        if (open)
            window.document.body.classList.add("kill-scroll")
        else
            window.document.body.classList.remove("kill-scroll")
    }, [open])

    return (
        <ModalStyled open={open}>
            <div className='shadow' onClick={handleClose}></div>
            <div className='modal-wrapper'>
                {children ? <div className='children'>{children}</div> : <div className='modal-custom'>
                    <p>{t("call")}</p>
                    <img src={Boy} alt="boy" className='boy' />
                    <CustomButton
                        text="back"
                        full={true}
                        onClick={() => navigate("/")}
                    />
                </div>}
            </div>
        </ModalStyled>
    );
}

const ModalStyled = styled.div`
    position: fixed;
    z-index: 50;
    left:0;
    top:0;
    width:100%;
    height: 100%;
    display: ${({ open }) => open ? "flex" : "none"};
    align-items: center;
    justify-content: center;
    padding: 20px;
    .close{
        position: absolute;
        top: -40px;
        right: -40px;
        width: 35px;
        height: 35px;
        background-color: #FFFFFF;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%; 
        cursor: pointer;
    }
    .shadow{
        position: fixed;
        left:0;
        top:0;
        width:100%;
        backdrop-filter: blur(5px); 
        height: 100%;
        background: rgba(0, 0, 0, 0.7);
    }

    .modal-wrapper{
        position: relative;
        z-index: 55;
    }   
    .children{
        position: relative;
        width: 700px;
        max-height: 500px;
        display: flex;
        align-items: center;
        justify-content: center;
        iframe{
            width: 100%;
            height: 350px;  
        }
        img{
            width:100%;
            border-radius: 16px;
        }
    }

        
        .modal-custom{
            width: 300px;
            height: 300px;
            background: #FFFFFF;
            border-radius: 32px;
            text-align: center;
            padding: 16px;
        }

    @media (max-width: 768px) {
        .children{
            width: 500px;
           max-height: 300px;
        }
    }
    @media (max-width: 576px) {
        .children{
            width: 300px;
            max-height: 300px;
        }
        .close{
            width: 25px;
            height: 25px;
            top: -30px;
            right: -30px;
            svg{
                width: 12px;
                height: 12px;
            }
        }
    }

`