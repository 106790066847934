import React, { useState } from 'react'
import styled from 'styled-components'
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import Input from '../../components/input/Input'
import CustomButton from '../../components/button/CustomButton';

import Enter from '../../assets/icons/EnterWhite';
import Plane from '../../assets/images/Plane.png';
import myAxios from '../../service';
import PhoneInput from '../../components/phoneInput/PhoneInput';
import CustomModal from '../../components/modal/CustomModal';

const FORM_NAMES = {
    fullName: "fullName",
    experience: "experience",
    speciality: "speciality",
    phoneNumber: "phoneNumber",
};


const Work = () => {
    const {
        handleSubmit,
        formState: { errors },
        reset,
        control,
        setError
    } = useForm();
    const [open, setOpen] = useState(false)
    const { t } = useTranslation()
    const handleClickOpen = (item) => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const work = (data) => {
        if(data.phoneNumber?.replaceAll("-", '').replaceAll(" ", '').length != 13){
            return setError('phoneNumber', {type: 'custom', message: "Telefon raqamni to'liq kiriting"})
        }
        const body = { ...data, type: "vacancy" }
        try {
            const res = myAxios.post("/sendMessage", body);
            handleClickOpen()
            reset({
                [FORM_NAMES.fullName]: "",
                [FORM_NAMES.experience]: "",
                [FORM_NAMES.phoneNumber]: "",
                [FORM_NAMES.speciality]: "",
            })
        } catch (error) {
            console.error(error);
        }
    }
    return (
        <StyledWork>
            <CustomModal handleClose={handleClose} open={open} />
            <img className='image' alt="Student" src={Plane} />
            <div className='Work'>
                <h1>{t("we_with")}</h1>
                <p>Ishga marhamat</p>
                <form onSubmit={handleSubmit(work)}
                    autoComplete="off"
                    noValidate
                >
                    <Input
                        placeholder={t("fio")}
                        FORM_NAMES={FORM_NAMES}
                        name={FORM_NAMES.fullName}
                        control={control}
                        errors={errors}
                        rules={{ required: { value: true, message: "Field required" } }}
                    />
                    <div className='skill'>
                        <Input
                            placeholder={t("experience")}
                            FORM_NAMES={FORM_NAMES}
                            name={FORM_NAMES.experience}
                            control={control}
                            errors={errors}
                            rules={{ required: { value: true, message: "Field required" } }}
                        />
                        <Input
                            name={FORM_NAMES.speciality}
                            control={control}
                            FORM_NAMES={FORM_NAMES}
                            placeholder={t("speciality")}
                            rules={{ required: { value: true, message: "Field required" } }}
                            errors={errors}
                        />
                    </div>

                    <PhoneInput
                        FORM_NAMES={FORM_NAMES}
                        name={FORM_NAMES.phoneNumber}
                        control={control}
                        errors={errors}
                        rules={{ required: { value: true, message: "Field required" } }}
                    />
                    <CustomButton
                        icon={<Enter />}
                        text="send"
                        full={true}
                        type="submit"
                    />
                </form>
            </div>
        </StyledWork>
    )
}

export default Work

const StyledWork = styled.div`
    .skill{
        display: flex;
        align-items: center;
        gap :16px;
    }
`