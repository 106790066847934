import React from 'react'

const Telephone = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M18.3282 22.5C17.4132 22.5 16.1278 22.1691 14.2032 21.0937C11.8627 19.7812 10.0524 18.5695 7.72457 16.2478C5.48019 14.0048 4.388 12.5526 2.85941 9.77109C1.13254 6.63046 1.42691 4.98421 1.75597 4.28062C2.14785 3.43968 2.72629 2.93671 3.47394 2.43749C3.89861 2.15926 4.34801 1.92075 4.81644 1.72499C4.86332 1.70484 4.90691 1.68562 4.94582 1.66827C5.17785 1.56374 5.52941 1.40577 5.97472 1.57452C6.27191 1.68609 6.53722 1.91437 6.95254 2.32452C7.80425 3.16452 8.96816 5.0353 9.39754 5.95405C9.68582 6.57327 9.8766 6.98202 9.87707 7.44046C9.87707 7.97718 9.60707 8.39109 9.27941 8.8378C9.218 8.92171 9.15707 9.00187 9.098 9.07968C8.74129 9.54843 8.663 9.6839 8.71457 9.92577C8.8191 10.4119 9.59863 11.8589 10.8797 13.1372C12.1608 14.4155 13.5661 15.1458 14.0541 15.2498C14.3063 15.3037 14.4446 15.2222 14.9283 14.8528C14.9977 14.7998 15.0689 14.745 15.1435 14.6901C15.6432 14.3184 16.0378 14.0555 16.5619 14.0555H16.5647C17.0208 14.0555 17.4113 14.2533 18.0582 14.5795C18.9019 15.0051 20.8289 16.1541 21.6741 17.0067C22.0852 17.4211 22.3144 17.6855 22.4264 17.9822C22.5952 18.4289 22.4363 18.7791 22.3327 19.0134C22.3153 19.0523 22.2961 19.095 22.276 19.1423C22.0787 19.6099 21.8387 20.0584 21.5593 20.482C21.061 21.2273 20.5561 21.8044 19.7133 22.1967C19.2805 22.4014 18.8069 22.5051 18.3282 22.5Z" fill="white" />
        </svg>

    )
}

export default Telephone