import React from "react";
import styled from "styled-components";
import Carousel from "../../../../components/carousel/Carousel";
import Bounce from "react-reveal/Bounce";
import { useTranslation } from "react-i18next";

import Map from "./assets/Map.svg";
import Icon1 from "./assets/Icon1.svg";
import Icon2 from "./assets/Icon2.svg";
import Icon3 from "./assets/Icon3.svg";
import Rocket from "../../../../assets/images/Plane.png";
import Time from "../../../../assets/images/Time.png";
import Pattern1 from "../../../../assets/images/Pattern1.png";
import CustomModal from "../../../../components/modal/CustomModal";

const Responsive = [
  {
    breakpoint: 1250,
    settings: {
      slidesToShow: 3,
      slidesToScroll: 1,
    },
  },
  {
    breakpoint: 1000,
    settings: {
      slidesToShow: 3,
      slidesToScroll: 1,
    },
  },
  {
    breakpoint: 768,
    settings: {
      slidesToShow: 3,
      slidesToScroll: 1,
    },
  },
];

const Branch = ({ branch }) => {
  const { list } = branch;
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);
  const [image, setImage] = React.useState(null);
  const handleClickOpen = (item) => {
    setOpen(true);
    setImage(item);
  };

  const handleClose = () => {
    setOpen(false);
    setImage(null);
  };

  return (
    <StyledBranch id="branch">
      <div className="container">
        <img src={Rocket} alt="rocket" className="rocket" />
        <h1 className="our_branchs">{t("our_branch")}</h1>

        <CustomModal handleClose={handleClose} open={open}>
          <img src={"https://ziyokoredu.uz/admin-api/" + image} alt="mainImg" />
        </CustomModal>

        <Carousel
          slidesToShow={1}
          customSettings={{
            slidesToScroll: 1,
          }}
        >
          {list?.map((item, idx) => (
            <div className="branch" key={idx}>
              <div className="main">
                <div className="main-img">
                  {item?.image?.map(
                    (i) =>
                      i.isMain && (
                        <Bounce left key={idx}>
                          <img
                            src={"https://ziyokoredu.uz/admin-api/" + i.url}
                            onClick={() => handleClickOpen(i.url)}
                            alt="mainImg"
                          />
                        </Bounce>
                      )
                  )}
                </div>
                <Bounce right>
                  <div className="branch-about">
                    <h1 className="branch-name">{item.name}</h1>
                    <div className="location">
                      <p>{item.address}</p>
                      <a
                        href={item.mapLink}
                        className="map"
                        target={"_blank"}
                        rel="noreferrer"
                      >
                        <img src={Map} alt="map" />
                      </a>
                    </div>
                    <div className="branch-info">
                      <div className="branch-icon">
                        <img src={Icon1} alt="Icon1" />
                      </div>
                      <p>{t("skilly_teacher")}</p>
                    </div>
                    <div className="branch-info">
                      <div className="branch-icon">
                        <img src={Icon2} alt="Icon2" />
                      </div>
                      <p>{t("high_result")}</p>
                    </div>
                    <div className="branch-info">
                      <div className="branch-icon">
                        <img src={Icon3} alt="Icon3" />
                      </div>
                      <p>{t("comfort_zone")}</p>
                    </div>
                  </div>
                </Bounce>
              </div>
              <div className="media">
                <div className="branch-info">
                  <div className="branch-icon">
                    <img src={Icon1} alt="Icon1" />
                  </div>
                  <p>{t("skilly_teacher")}</p>
                </div>
                <div className="branch-info">
                  <div className="branch-icon">
                    <img src={Icon2} alt="Icon2" />
                  </div>
                  <p>{t("high_result")}</p>
                </div>
                <div className="branch-info">
                  <div className="branch-icon">
                    <img src={Icon3} alt="Icon3" />
                  </div>
                  <p>{t("comfort_zone")}</p>
                </div>
              </div>
              <div className="others">
                {item?.image.length < 5 ? (
                  <div className="img-group">
                    {" "}
                    {item?.image?.map(
                      (i, index) =>
                        !i.isMain && (
                          <div className="other-img" key={index}>
                            <img
                              src={"https://ziyokoredu.uz/admin-api/" + i.url}
                              onClick={() => handleClickOpen(i.url)}
                              alt="others"
                            />
                          </div>
                        )
                    )}{" "}
                  </div>
                ) : (
                  <Carousel
                    slidesToShow={3}
                    responsive={Responsive}
                    customSettings={{
                      arrows: false,
                      autoplay: true,
                      autoplaySpeed: 2500,
                      slidesToScroll: 1,
                      draggable: false,
                      swipteToSlide: false,
                      touchMove: false,
                      swipe: false
                    }}
                    className="branch-carousel"
                  >
                    {" "}
                    {item?.image?.map(
                      (i, index) =>
                        !i.isMain && (
                          <div key={index} className="other-img">
                            <img
                              src={"https://ziyokoredu.uz/admin-api/" + i.url}
                              onClick={() => handleClickOpen(i.url)}
                              alt="others"
                            />
                          </div>
                        )
                    )}
                  </Carousel>
                )}
              </div>
            </div>
          ))}
        </Carousel>
        <img src={Time} alt="Time" className="time" />
        <img src={Pattern1} alt="Pattern1" className="pattern1" />
      </div>
    </StyledBranch>
  );
};

export default Branch;

const StyledBranch = styled.div`
  position: relative;
  padding-bottom: 70px;

  .media {
    display: none;
  }
  .rocket {
    position: absolute;
    top: -50px;
    right: 64px;
  }
  .time {
    position: absolute;
    bottom: -80px;
    right: 40px;
    z-index: -1;
  }
  .pattern1 {
    position: absolute;
    bottom: -320px;
    left: 0px;
    z-index: -5;
    max-width: 80%;
  }
  .our_branchs {
    font-weight: 700;
    font-size: 40px;
    line-height: 50px;
    text-align: center;
  }

  .branch {
    background: #ffffff;
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.08);
    border-radius: 32px;
    margin-top: 32px;
    width: 96%;
    padding: 16px;

    .main {
      display: flex;
      align-items: center;
      gap: 16px;

      .main-img {
        width: 50%;
        border-radius: 24px;
        /* height: 250px;
                object-fit: cover;
                overflow: hidden; */
        img {
          width: 100%;
          border-radius: 24px;
        }
      }
      .branch-about {
        width: 50%;

        h1 {
          font-weight: 700;
          font-size: 32px;
          line-height: 40px;
          margin-bottom: 10px;
        }
        .location {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 18px;

          p {
            font-weight: 600;
            font-size: 18px;
            line-height: 22px;
            width : calc(100% - 54px)
          }
          .map {
            background: #ffffff;
            box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
            height: 48px;
            width: 48px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
        .branch-info {
          display: flex;
          align-items: center;
          gap: 8px;
          margin-bottom: 12px;

          .branch-icon {
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            background: linear-gradient(45deg, #0065ff 0%, #4e9dea 100%);
            width: 32px;
            height: 32px;
          }
          p {
            font-weight: 600;
            font-size: 16px;
            line-height: 20px;
          }
        }
      }
    }

    .others {
      margin-top: 16px;

      .other-img {
        /* height: 150px; */
        padding: 0 8px;
        img {
          width: 100%;
          border-radius: 24px;
          height: 100%;
          object-fit: cover;
        }
      }
      .img-group {
        display: flex;
        align-items: center;
        gap: 0.5%;
        .other-img {
          width: 33%;
        }
      }
    }
  }
  @media (max-width: 1000px) {
    .branch {
      .others {
        .img-group {
          .other-img {
            width: 48%;
          }
        }
      }
    }
  }

  @media (max-width: 776px) {
    .slick-arrow.slick-prev {
      left: initial !important;
      right: 42px !important;
      top: initial !important;
      bottom: 90px !important;
    }
    .slick-arrow.slick-next {
      right: 42px !important;
      top: initial !important;
      bottom: 30px !important;
    }
    .prev {
      left: initial !important;
      right: 62px !important;
      top: initial !important;
      bottom: 120px !important;
    }
    .next {
      right: 62px !important;
      top: initial !important;
      bottom: 60px !important;
    } 

    .branch {
      .main {
        .branch-about {
          .location {
            display: block;
            margin-bottom: 0;
            p {
              margin-bottom: 8px;
              font-size: 14px;
              line-height: 17px;
              width:100%;
            }
          }
        }
        .main-img {
          height: 160px;
          img {
            height: 100%;
            overflow: hidden;
            object-fit: cover;
          }
        }
        .branch-about {
          h1 {
            font-size: 20px;
            line-height: 25px;
          }
          .branch-info {
            display: none;
          }
        }
      }
    }
    .others {
      display: none;
    }
    padding-bottom: 40px;
    .media {
      display: block;
      margin-top: 16px;
      .branch-info {
        display: flex;
        align-items: center;
        gap: 8px;
        margin-bottom: 12px;

        .branch-icon {
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          background: linear-gradient(45deg, #0065ff 0%, #4e9dea 100%);
          width: 32px;
          height: 32px;
        }
        p {
          font-weight: 600;
          font-size: 16px;
          line-height: 20px;
        }
      }
    }
  }
  @media (max-width: 576px) {
   
    .time {
      display: none;
    }
    h1.our_branchs {
      font-size: 32px;
      line-height: 40px;
    }
   
  }
  @media (max-width: 420px) {
    .container {
      padding: 0;
    }
    .slick-arrow.slick-prev {
      left: initial !important;
      right: 32px !important;
      top: initial !important;
      bottom: 110px !important;
    }
    .slick-arrow.slick-next {
      right: 32px !important;
      top: initial !important;
      bottom: 50px !important;
    }
    .prev {
      left: initial !important;
      right: 52px !important;
      top: initial !important;
      bottom: 140px !important;
    }
    .next {
      right: 52px !important;
      top: initial !important;
      bottom: 80px !important;
    }
  }
`;
