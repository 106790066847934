import React from 'react'
import styled from 'styled-components'
import Fade from 'react-reveal/Fade';
import { useTranslation } from "react-i18next";
import CountUp from "react-countup";

import Image1 from "./assets/images/Image2.jpg"

// import Target from "./assets/icons/Target"
import Branch from "./assets/icons/Branch"
import Student from "./assets/icons/Student"
import Staff from "./assets/icons/Staff"
import Graduate from "./assets/icons/Graduate"




const About = () => {
    const { t } = useTranslation()
    return (
        <StyledAbout id="about_us">
            <div className='container'>
                <div className='about-main'>
                    <h1>{t("about_us")}</h1>
                    <div className='about'>
                        <Fade left>
                            <div className='about-left'>
                                <h3>{t("branch_label")}
                                    {/* <span>{t("subjects")}</span>
                                    {t("branch_label2")} */}
                                </h3>
                                <p>{t("branch_text")}</p>
                            </div>
                        </Fade>
                        <Fade right>
                            <div className='about-right'>
                                <img src={Image1} alt="Image1" />
                                {/* <div className='target'>
                                    <Target />
                                </div> */}
                            </div>
                        </Fade>
                        <Fade left>
                            <p className='media'>{t("branch_text")}</p>
                        </Fade>
                    </div>

                </div>
            </div>
            <div className='line'>
                <div className='container'>
                    <div className='line-list'>
                        <div className='line-item'>
                            <div className='line-img'>
                                <Branch />
                            </div>
                            <div className='line-text'>
                                <p>{t("branch")}</p>
                                <h2><CountUp duration={5} end={6} />+</h2>
                            </div>
                        </div>
                        <div className='line-item'>
                            <div className='line-img'>
                                <Graduate />
                            </div>
                            <div className='line-text'>
                                <p>{t("graduate")}</p>
                                <h2><CountUp duration={5} end={25000} />+</h2>
                            </div>
                        </div>
                        {/* <div className='line-item'>
                            <div className='line-img'>
                                <Student />
                            </div>
                            <div className='line-text'>
                                <p>{t("student")}</p>
                                <h2><CountUp duration={5} end={2500} />+</h2>
                            </div>
                        </div> */}
                        <div className='line-item'>
                            <div className='line-img'>
                                <Staff />
                            </div>
                            <div className='line-text'>
                                <p>{t("staff")}</p>
                                <h2><CountUp duration={5} end={150} />+</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </StyledAbout>
    )
}

export default About

const StyledAbout = styled.div`
margin-top:35px;
overflow: hidden;
position: relative;
padding-bottom: 70px;
.media{
    display: none;
}
    h1{
        font-weight: 700;
        font-size: 40px;
        line-height: 50px;
        text-align: center;
    }
    .about{
        display: flex;
        align-items: center;
        gap: 44px;
        margin-top: 30px;
        justify-content: space-between;

        h3{
            font-weight: 700;
            font-size: 24px;
            line-height: 30px;
            span{
                color: #0065FF;
            }
        }
        p{
            font-weight: 700;
            margin-top: 14px;
            line-height: 20px;
        }
        &-right{
            width : 45%;
            position: relative;
            &> img{
                width: 100%;
                border-radius: 32px;
            }
            .target{
                position: absolute;
                display: flex;
                align-items: center;
                justify-content: center;
                background: #FFFFFF;
                box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
                width: 72px;
                height: 72px;
                bottom: 18px;
                left: 18px;
                border-radius: 50%;
            }
        }
        &-left{
            width: 55%;
        }
    }

    .line{
        height: 150px;
        background: rgba(0, 101, 255, 0.1);
        transform: rotate(-3deg);
        width: 102%;
        position: relative;
        left: -1%;
        margin-top: 70px;
        display: flex;
        align-items: center;

        &-list{
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
        &-item{
            display: flex;
            align-items: center;
            gap: 10px;

            .line-img{
                background: linear-gradient(45deg, #0065FF 0%, #4E9DEA 100%);
                width: 56px;
                height: 56px;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }

    @media (max-width: 992px) {
        .about{
            .about-left{
                p{
                    display: none;
                }
            }
        }
    }
    @media (max-width: 776px) {
        .media{
            display: block;
        }
        .about{
            display: block;
            .about-left{
                width: 100%;
            }
            .about-right{
                margin-top: 12px;
                width: 100%;
            }

        }
        .line{
            height: 180px;
            &-list{
                flex-wrap: wrap;
            }
            &-item{
                width:50%;
                margin: 12px 0;
                padding-left: 12px;
                h2{
                    font-size: 20px;
                    line-height: 24px;
                }
                p{
                    font-size: 14px;
                    line-height: 17px;
                }
                .line-text{
                    width: calc(100% - 60px);
                }
            }
        }
    }
    @media (max-width: 576px) {
        h1{
            font-size: 32px;
            line-height: 40px;
        }
        .about{
            .about-left{
                h3{
                    font-size: 16px;
                    line-height: 20px;
                }
            }
        }
        
    }
`